import React, { useEffect, useState } from 'react';
import PageWrapper from '../wrappers/PageWrapper';
import GreenButton from '../Global/GreenButton';
import integrationService from '../../services/integrations.service';
import Pagination from '../elements/Contacts/TableBox/elements/Pagination';
import APIName from '../Global/popup/variants/APIName';
import usePopup from '../../helpers/usePopup';
import KeysList from '../elements/Settings/KeysList';

const Settings = () => {
  const { initPopup } = usePopup();
  /* eslint-disable no-unused-vars */
  const [keys, setKeys] = useState([]);
  const [page, setPage] = useState(1);
  const [refetch, setRefetch] = useState(false);
  const [nextPageAvaible, setNextPageAvaible] = useState(null);
  const [prevPageAvaible, setprevPageAvaible] = useState(null);
  const [pageAmmount, setPageAmmount] = useState();
  const [loading, setLoading] = useState(false);
  const page_size = 100;
  const goToNextPage = () => {
    if (nextPageAvaible) {
      setPage(page + 1);
      document.getElementById('queue-list').scrollIntoView();
      document.getElementById('queue-list').scrollTo(0, 0);
    }
  };

  const goToPrevPage = () => {
    if (prevPageAvaible) {
      setPage(page - 1);
      document.getElementById('queue-list').scrollIntoView();
      document.getElementById('queue-list').scrollTo(0, 0);
    }
  };
  const fetchKeys = () => {
    setLoading(true);
    integrationService.getAPIKeys(page).then((r) => {
      if (r.results) {
        setKeys(r.results);
        setNextPageAvaible(r.next);
        setprevPageAvaible(r.previous);
        setPageAmmount(Math.ceil(r.count / page_size));
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    fetchKeys();
  }, [page]);
  useEffect(() => {
    fetchKeys();
  }, [refetch]);
  const handleGenerateKey = () => {
    initPopup(<APIName fetchKeys={fetchKeys} />);
  };
  return (
    <PageWrapper>
      <div className='section-wrapper'>
        <GreenButton handleClick={handleGenerateKey}>Wygeneruj klucz API</GreenButton>
        {!loading && (
          <KeysList
            setRefetch={setRefetch}
            data={keys}
            page={page}
          />
        )}
        {nextPageAvaible && (
          <Pagination
            pageAmmount={pageAmmount}
            page={page}
            nextPageAvaible={nextPageAvaible}
            goToNextPage={goToNextPage}
            goToPrevPage={goToPrevPage}
            prevPageAvaible={prevPageAvaible}
          />
        )}
      </div>
    </PageWrapper>
  );
};

export default Settings;
