import './scss/main.scss';
import { createTheme, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import routes from './routes';
import { StateProvider } from './Context';
import Notification from '././components/Global/Notification';

function App() {
  const theme = createTheme({
    components: {
      // Name of the component
      MuiSelect: {
        styleOverrides: {
          // Name of the slot
          root: {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#333033', // Change the border color to your desired color
            },
          },
          label: {
            color: '#333033', // Change the label color to your desired color
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <StateProvider>
        <Notification />
        <BrowserRouter>{routes}</BrowserRouter>
      </StateProvider>
    </ThemeProvider>
  );
}

export default App;
