import React from 'react';
import { ReactComponent as Logo } from '../../../../assets/images/logo_mesango.svg';
import TitleWrapperComponent from './TitleWrapperComponent';
import { useNavigate } from 'react-router-dom';
import RoutesPaths from '../../../../RoutesPaths';
import ActivatePopup from '../../../Global/ActivatePopup';
import MobileMenuIcon from '../../../Global/MobileMenuIcon';
import TitleUrlList from './TitleUrlList';

const Header = ({ isActiveMenu, setDisplayMenu }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className='header-container'>
        <div className='logo-container'>
          <Logo
            onClick={() => navigate(RoutesPaths.Home.path)}
            style={{ width: '150px', cursor: 'pointer', transform: 'translateY(4px)' }}
          />
        </div>
        <div className='container'>
          <div className='header-wrapper'>
            <TitleWrapperComponent />
          </div>
        </div>
        <MobileMenuIcon
          setDisplayMenu={setDisplayMenu}
          isActiveMenu={isActiveMenu}
        />
      </div>
      {window.screen.width < 1000 && (
        <div className='mobile-activate'>
          <ActivatePopup />
          <div className='header-titles-wrapper'>{TitleUrlList.find((element) => element.url === location.pathname).component}</div>
        </div>
      )}
    </>
  );
};

export default Header;
