import React from 'react';
import { ReactComponent as LogoutIcon } from '../../../../../assets/logout.svg';
import usePopup from '../../../../../helpers/usePopup';
import Logout from '../../../../Global/popup/variants/Logout';
const Profile = () => {
  const { initPopup } = usePopup();
  const logout = () => {
    console.log('WYLOGOWYWANIE');
    initPopup(<Logout />);
  };

  return (
    <div className='profile-wrapper'>
      <div
        className='container'
        onClick={() => logout()}
      >
        <LogoutIcon
          style={{ color: '#ACACAC' }}
          fontSize='large'
        />
        <span className='logout'>Wyloguj się</span>
      </div>
    </div>
  );
};

export default Profile;
