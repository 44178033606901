import React, { useEffect, useRef } from 'react';
import PageWrapper from '../wrappers/PageWrapper';
import { Grid } from '@mui/material';
import messagesService from '../../services/messages.service';
import SingleFile from '../elements/Import/SingleFile';
import exampleFile from '../../assets/wiad.csv';
const Import = () => {
  const [importedFiles, setImportedFiles] = React.useState([]);
  const fileInput = useRef(null);
  const checkIntervalRef = useRef(null);
  const handleImportCSV = (e) => {
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append('file', file);
    if (formData) {
      messagesService.importMessages(formData).then((res) => {
        if (!res.errors) {
          messagesService.fetchAddedFiles().then((response) => {
            if (response.errors) {
              console.log(response.errors[0]);
            } else {
              setImportedFiles(response.results);
            }
          });
          fileInput.current.value = '';
        } else {
          console.log(res.errors[0]);
          fileInput.current.value = '';
        }
      });
    }
  };
  const handleFetchAddedFiles = () => {
    messagesService.fetchAddedFiles().then((response) => {
      if (response.errors) {
        console.log(response.errors[0]);
      } else {
        setImportedFiles(response.results);
      }
    });
  };
  const checkFileStatus = () => {
    handleFetchAddedFiles();
    const hasActiveFiles = importedFiles.some((file) => file.status === 'new' || file.status === 'ongoing');

    if (!hasActiveFiles && checkIntervalRef.current) {
      clearInterval(checkIntervalRef.current);
    }
  };

  useEffect(() => {
    checkIntervalRef.current = setInterval(checkFileStatus, 5000);
    return () => {
      if (checkIntervalRef.current) {
        clearInterval(checkIntervalRef.current);
      }
    };
  }, [importedFiles]);
  const handleDownloadExampleFile = () => {
    const fileName = 'example.csv'; // Nazwa pliku do pobrania

    // Tworzenie linku do pobrania pliku
    const link = document.createElement('a');
    link.href = exampleFile;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };
  useEffect(() => {
    handleFetchAddedFiles();
  }, []);
  return (
    <PageWrapper>
      <div className='import-wrapper'>
        <div className='import-header'>
          <button
            className='global-btn'
            style={{ position: 'relative', marginRight: '30px' }}
          >
            Dodaj plik{' '}
            <input
              type='file'
              onChange={handleImportCSV}
              ref={fileInput}
            />
          </button>
          <span
            className='underline-text'
            onClick={handleDownloadExampleFile}
          >
            Pobierz przykładowy plik
          </span>
        </div>
        <div className='comissioned-messages'>
          <h1 className='import-subheader'>Dodane pliki</h1>
          <Grid
            container
            className='comissioned-header'
          >
            <Grid
              xs={0.2}
              item
            />
            <Grid
              xs={3}
              item
            >
              <span className='header-element'>Nazwa</span>
            </Grid>
            <Grid
              xs={3}
              item
            >
              <span className='header-element'>Status</span>
            </Grid>
            <Grid
              xs={3}
              item
            >
              <span className='header-element'>Kolejka wiadomości</span>
            </Grid>
            <Grid
              xs={2.8}
              item
            />
          </Grid>
          <ul className='messages-list'>
            {importedFiles.length <= 0 && (
              <div className='no-data-to-show-container'>
                <span className='no-data-to-show'>Wygląda na to, że nic tu nie ma.</span>
              </div>
            )}
            {importedFiles.map((file, index) => {
              return (
                <li
                  className='list-element'
                  key={index}
                >
                  <SingleFile
                    file={file}
                    index={index + 1}
                    handleFetchAddedFiles={handleFetchAddedFiles}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Import;
