import React, { useState } from 'react';
import useNotification from '../../../../helpers/notifications/notificationGenerator';
import usePopup from '../../../../helpers/usePopup';
import { NOTIFICATION_ENUM } from '../../../../helpers/ENUMS';
import validateAddContact from '../../../../helpers/validators/validateAddContact';
import contactService from '../../../../services/contact.service';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import GreenButton from '../../GreenButton';

const EditContactPopUp = ({ user, fetchContacts }) => {
  const [contactData, setContactData] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
    description: user.description,
    phone_number: user.phone_number,
    id: user.id,
  });
  const { generateNotification } = useNotification();
  const { closePopup } = usePopup();
  const handleInputChange = (e) => {
    // Sprawdź, czy id to 'phone_number'
    if (e.target.id === 'phone_number') {
      const value = e.target.value;
      // Sprawdź, czy wartość zaczyna się od '+' i zawiera tylko cyfry, lub czy jest to tylko cyfrowe
      if (!(value === '+' || /^\+?\d*$/.test(value))) {
        return; // Jeśli nie, to nie aktualizuj stanu
      }
    }

    // Aktualizuj stan dla wszystkich innych przypadków
    setContactData({ ...contactData, [e.target.id]: e.target.value });
  };
  const handleAddContact = (e) => {
    e.preventDefault();
    const validateInfo = validateAddContact({ ...contactData });
    generateNotification({ ...validateInfo, time: 4000 });
    if (validateInfo.type == NOTIFICATION_ENUM.SUCCESS) {
      contactService.updateContact({ ...contactData }).then(() => {
        fetchContacts();
        closePopup();
      });
    }
  };
  const handleClosePopup = (e) => {
    e.preventDefault();
    closePopup();
  };
  return (
    <div className='add-contacts-popup'>
      <p className='title'>Edytuj kontakt</p>
      <Box
        component='form'
        sx={{ flexDirection: 'column' }}
        noValidate
        autoComplete='off'
        padding={5}
      >
        <div className='input-wrapper'>
          <TextField
            onChange={(e) => handleInputChange(e)}
            value={contactData.first_name}
            id='first_name'
            label='Imię'
            variant='standard'
          />
        </div>
        <div className='input-wrapper'>
          <TextField
            onChange={(e) => handleInputChange(e)}
            value={contactData.last_name}
            id='last_name'
            label='Nazwisko'
            variant='standard'
          />
        </div>
        <div className='input-wrapper'>
          <TextField
            onChange={(e) => handleInputChange(e)}
            value={contactData.phone_number}
            id='phone_number'
            label='Numer telefonu'
            variant='standard'
          />
        </div>
        <div className='button-wrapper'>
          <button
            onClick={(e) => handleClosePopup(e)}
            className='btn close-contant-button'
          >
            Anuluj
          </button>
          <GreenButton
            handleClick={(e) => handleAddContact(e)}
            noMargin
          >
            Zmień
          </GreenButton>
        </div>
      </Box>
    </div>
  );
};

export default EditContactPopUp;
