import { useContextSelector } from 'use-context-selector';
import { Context } from '../../Context';

function useNotification() {
  const notification = useContextSelector(Context, (state) => state.notification);
  const setNotification = useContextSelector(Context, (state) => state.setNotification);

  const generateNotification = ({ type, time, content }) => {
    setNotification({ type, content, display: true });
    setTimeout(() => {
      setNotification({ ...notification, display: false });
    }, time);
  };

  return { generateNotification };
}

export default useNotification;
