import React, { useState } from 'react';
import { CssTextField } from '../../../scss/settings/StyledMUIComponents';
import { ReactComponent as Close } from '../../../assets/chip_close.svg';
import GreenButton from '../../Global/GreenButton';
import { handlePostNewPassword } from '../../../services/profile.service';
const ResetPassword = ({ setResetPassword }) => {
  const [form, setForm] = useState({ old: '', new: '', repeat: '' });
  const [error, setError] = useState({});
  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
    setError({});
  };
  const handleChangePassword = async () => {
    if (form.new === form.repeat) {
      handlePostNewPassword(form.old, form.new)
        .then((response) => response.json())
        .then((data) => {
          if (data.type && data.type === 'validation_error') {
            setError({ old: 'Błędne hasło' });
          }
        })
        .catch((err) => console.log(err));
    } else {
      setError((prevState) => {
        return { ...prevState, repeat: 'Hasła nie są takie same' };
      });
    }
  };
  return (
    <div className='sub-component-container'>
      <div className='close-container'>
        <Close
          width={12}
          height={12}
          onClick={() => setResetPassword(false)}
        />
      </div>
      <div className='container'>
        <h1 className='sub-component-header'>Zmień hasło</h1>
        <p className='sub-component-text'>
          Pamiętaj, że hasło powinno składać się z min. 8 znaków i zawierać przynajmniej jedną WIELKĄ literę oraz cyfrę.
        </p>
        <CssTextField
          id='outlined-required'
          label='Stare hasło'
          value={form.old}
          name='old'
          type={'password'}
          style={{ marginBottom: error.old ? '10px' : '40px' }}
          onChange={(e) => handleChange(e)}
          error={error.old}
          hasValue={form.old?.length > 0}
          inputProps={{
            autoComplete: 'new-password',
          }}
        />
        {error.old && <span className={'error error-old'}>{error.old}</span>}
        <CssTextField
          id='outlined-required'
          label='Nowe hasło'
          value={form.new}
          name='new'
          type={'password'}
          onChange={(e) => handleChange(e)}
          error={error.repeat}
          hasValue={form.new?.length > 0}
          inputProps={{
            autoComplete: 'new-password',
          }}
        />
        <CssTextField
          id='outlined-required'
          label='Powtórz nowe hasło'
          value={form.repeat}
          name='repeat'
          type={'password'}
          style={{ marginBottom: error.repeat ? '10px' : '40px' }}
          onChange={(e) => handleChange(e)}
          error={error.repeat}
          hasValue={form.repeat?.length > 0}
          inputProps={{
            autoComplete: 'new-password',
          }}
        />
        {error.repeat && <span className={'error'}>{error.repeat}</span>}
        <GreenButton
          handleClick={handleChangePassword}
          noMargin
        >
          Zatwierdź hasło
        </GreenButton>
      </div>
    </div>
  );
};

export default ResetPassword;
