import React from 'react';

const PaymentType = ({ chosenType, setChosenType, text, type, error, setError }) => {
  return (
    <div className={`payment-type-container ${error ? 'error' : ''}`}>
      <div>
        <input
          type='radio'
          id='option1'
          checked={chosenType === text}
        />
        <label
          htmlFor='option1'
          onClick={() => {
            setChosenType(text);
            setError((prevState) => {
              return { ...prevState, type: '' };
            });
          }}
        />
        <span className={'payment-text payment-type'}>{text}</span>
      </div>
      {type}
    </div>
  );
};

export default PaymentType;
