import React, { useState } from 'react';
import GreenButton from '../../GreenButton';
import integrationService from '../../../../services/integrations.service';
import { ReactComponent as Close } from '../../../../assets/close.svg';
import usePopup from '../../../../helpers/usePopup';
import { NOTIFICATION_ENUM } from '../../../../helpers/ENUMS';
import useNotification from '../../../../helpers/notifications/notificationGenerator';

const APIName = ({ fetchKeys }) => {
  const { closePopup } = usePopup();
  const { generateNotification } = useNotification();
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [error, setError] = useState('');
  const handleGenerateKey = () => {
    if (name === '') {
      setError('Nazwa klucza nie może być pusta');
      return;
    }
    setLoading(true);
    integrationService.createKey(name).then((r) => {
      if (r.api_key) {
        setApiKey(r.api_key);
        setLoading(false);
        setError('');
        fetchKeys();
      } else {
        setLoading(false);
        setApiKey('');
        setError('Wystąpił błąd podczas generowania klucza');
      }
    });
  };
  const copyToClipboard = () => {
    try {
      navigator.clipboard.writeText(apiKey);
      generateNotification({
        content: `Klucz został skopiowany do schowka!`,
        type: NOTIFICATION_ENUM.SUCCESS,
        time: 4000,
      });
    } catch (err) {
      console.log(err);
      generateNotification({
        content: `Coś poszło nie tak podczas kopiowania`,
        type: NOTIFICATION_ENUM.ERROR,
        time: 4000,
      });
    }
  };
  return loading ? (
    <div className='loader-container'>
      <span className='loader'></span>
    </div>
  ) : !apiKey ? (
    <div className={`enter-name-container ${error ? 'container-error' : null}`}>
      <h1 className='enter-name-header'>Wpisz nazwę nowego klucza API</h1>
      <input
        type='text'
        className='enter-name-input'
        placeholder='Nazwa klucza'
        value={name}
        onChange={(e) => {
          setName(e.target.value);
          setError('');
        }}
      />
      {error && <span className='error-message'>{error}</span>}
      <div className='buttons-container'>
        <span
          className='button-cancel'
          onClick={() => {
            closePopup();
            setApiKey('');
          }}
        >
          Anuluj
        </span>
        <GreenButton
          handleClick={handleGenerateKey}
          noMargin
        >
          Dodaj
        </GreenButton>
      </div>
    </div>
  ) : (
    <div className='copy-key-container'>
      <div className='close-container'>
        <Close
          onClick={() => {
            closePopup();
            setApiKey('');
          }}
        />
      </div>
      <h1 className='copy-key-header'>Wygenerowany klucz API (Nazwa klucza)</h1>
      <input
        type='text'
        className='enter-name-input'
        value={apiKey}
        disabled
      />
      <GreenButton
        handleClick={copyToClipboard}
        noMargin
      >
        Skopiuj klucz API
      </GreenButton>
    </div>
  );
};

export default APIName;
