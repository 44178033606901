import React, { useState } from 'react';
import PageWrapper from '../wrappers/PageWrapper';
import ProfileComponent from '../elements/Profile/ProfileComponent';
import ResetPassword from '../elements/Profile/ResetPassword';
import DeleteAccount from '../elements/Profile/DeleteAccount';

const Profile = () => {
  const [resetPassword, setResetPassword] = useState(false);
  const [deleteAccount, setDeleteAccount] = useState(false);
  return (
    <PageWrapper>
      <div className='profile'>
        {resetPassword ? (
          <ResetPassword setResetPassword={setResetPassword} />
        ) : deleteAccount ? (
          <DeleteAccount setDeleteAccount={setDeleteAccount} />
        ) : (
          <ProfileComponent
            setResetPassword={setResetPassword}
            setDeleteAccount={setDeleteAccount}
          />
        )}
      </div>
    </PageWrapper>
  );
};

export default Profile;
