import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import { ReactComponent as Close } from '../../../../../assets/chip_close.svg';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';

const ContactAutocomplete = ({ selectedValues, setSelectedValues }) => {
  const [inputValue, setInputValue] = useState('');
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const handleInputChange = (event, newInputValue) => {
    // Sprawdzenie, czy wprowadzana wartość zawiera tylko cyfry
    if (/^\d*$/.test(newInputValue)) {
      setInputValue(newInputValue);
    }
    setHighlightedIndex(-1);
  };

  const handleChange = (event, newValue) => {
    // Tworzenie obiektów z wprowadzonych stringów
    const updatedValues = newValue.map((value) => (typeof value === 'string' ? { name: value } : value));
    setSelectedValues(updatedValues);
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Backspace') {
      event.stopPropagation(); // Zapobieganie propagacji zdarzenia

      if (inputValue === '') {
        if (highlightedIndex >= 0) {
          // Usunięcie wyróżnionego chipa
          setSelectedValues(selectedValues.filter((_, index) => index !== highlightedIndex));
          setHighlightedIndex(-1);
        } else if (selectedValues.length > 0) {
          // Wyróżnienie ostatniego chipa
          setHighlightedIndex(selectedValues.length - 1);
        }
      }
    }
    if (event.key === 'Enter' && inputValue.length !== 9) {
      event.stopPropagation();
      event.preventDefault();
    }
  };
  const handleBlur = () => {
    // Dodawanie wartości tylko, jeśli ma dokładnie 9 cyfr
    if (inputValue.length === 9 && !selectedValues.some((value) => value.name === inputValue)) {
      setSelectedValues([...selectedValues, { name: inputValue }]);
      setInputValue('');
    }
  };

  return (
    <Autocomplete
      multiple
      id='free-solo-demo'
      freeSolo
      inputValue={inputValue}
      onInputChange={handleInputChange}
      value={selectedValues}
      onChange={handleChange}
      onBlur={handleBlur}
      options={[]}
      getOptionLabel={(option) => option.name}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={index}
            variant='outlined'
            label={option.name}
            {...getTagProps({ index })}
            sx={{
              margin: '2px',
              backgroundColor: '#EDEDED',
              border: highlightedIndex === index ? '1px solid #EF8533' : 'none',
              color: '#252525',
              padding: '3px 12px',
              fontFamily: 'Montserrat, sans-serif !important',
              fontSize: '13px',
              fontWeight: '600',
              borderRadius: '100px',
              boxSizing: 'border-box',
              '.MuiChip-label': {
                padding: '0',
              },
              '.MuiAutocomplete-tag': {
                padding: '14px',
              },
            }}
            deleteIcon={<Close style={{ color: '#252525', marginLeft: '25px', marginRight: '0' }} />}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant='standard'
          onKeyDown={handleKeyDown}
        />
      )}
      sx={{
        width: '100%',
        '& .MuiInputBase-root .MuiInputBase-input': {
          padding: '10px',
          caretColor: '#EF8533',
        },
        '& .MuiInputBase-root': {
          padding: '10px',
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: '#DBDBDB',
          display: 'none',
        },
        '& .MuiInput-underline:before': {
          borderBottom: '1px solid #DBDBDB !important',
        },
      }}
    />
  );
};

export default ContactAutocomplete;
