import React, { useState } from 'react';
import GreenButton from '../../GreenButton';
import messagesService from '../../../../services/messages.service';
import { NOTIFICATION_ENUM } from '../../../../helpers/ENUMS';
import useNotification from '../../../../helpers/notifications/notificationGenerator';
import usePopup from '../../../../helpers/usePopup';

const SendMessagePopup = ({ user }) => {
  const [text, setText] = useState('');
  const characterCount = text.length; // Obliczanie liczby znaków
  const [validateError, setValidateError] = useState('');
  const { generateNotification } = useNotification();
  const { closePopup } = usePopup();
  const validate = () => {
    let isOk = true;
    if (!text) {
      isOk = false;
      setValidateError('Uzupełnij to pole');
    }
    return isOk;
  };
  const sendMessage = (e) => {
    e.preventDefault();
    let isOK = validate();
    if (isOK) {
      messagesService
        .sendMessage({
          msg: text,
          recipients_phones: [user.phone_number],
        })
        .then((r) => {
          if (r.results) {
            generateNotification({
              content: `Wiadomość trafiła do kolejki wysyłania`,
              type: NOTIFICATION_ENUM.SUCCESS,
              time: 4000,
            });
            closePopup();
            setText('');
          }
        });
    } else {
      generateNotification({
        content: `Uzupełnij wymagane pola`,
        type: NOTIFICATION_ENUM.ERROR,
        time: 4000,
      });
    }
  };
  return (
    <div className='send-msg-container'>
      <h1 className='send-msg-header'>Wyślij nową wiadomość do:</h1>
      <div className='send-msg-recipient'>
        <span className='user-avatar'>{user.first_name[0]}</span>
        <span className='user-name'>
          {user.first_name} {user.last_name}
        </span>
      </div>
      <div className='msg-content-container'>
        <textarea
          name=''
          id=''
          cols='30'
          rows='10'
          maxLength={200}
          className='msg-content'
          onChange={(e) => {
            setText(e.target.value);
            setValidateError('');
          }}
        ></textarea>
        <span className='character-count'>{characterCount}/200</span>
      </div>
      <span className='msg-error'>{validateError}</span>
      <div className='buttons-container'>
        <span
          className='button-cancel'
          onClick={() => {
            closePopup();
            setText('');
          }}
        >
          Anuluj
        </span>
        <GreenButton
          noMargin
          handleClick={sendMessage}
        >
          Wyślij
        </GreenButton>
      </div>
    </div>
  );
};

export default SendMessagePopup;
