import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import PreloaderComponent from './PreloaderComponent';

const RouteWrapper = ({ children }) => {
  return <Suspense fallback={<PreloaderComponent />}>{children}</Suspense>;
};

RouteWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RouteWrapper;
