import React, { useEffect, useRef, useState } from 'react';
import usePopup from '../../../../helpers/usePopup';
import { ReactComponent as CloseIcon } from '../../../../assets/close.svg';
import authenticationService from '../../../../services/authentication.service';
import useNotification from '../../../../helpers/notifications/notificationGenerator';
import { NOTIFICATION_ENUM } from '../../../../helpers/ENUMS';

const ActivateAccountPopup = () => {
  let length = 6;
  const [values, setValues] = useState(Array(length).fill(''));
  const inputsRef = useRef([]);

  useEffect(() => {
    // Set the focus to the first input on component mount
    if (inputsRef.current[0]) {
      inputsRef.current[0].focus();
    }
  }, []);

  useEffect(() => {
    if (values.every((value) => value !== '')) {
      authenticationService.activateAccount([...values].join('')).then((r) => {
        if (r.ok) {
          let user = JSON.parse(localStorage.getItem('currentUser'));
          user.is_activated = true;
          localStorage.setItem('currentUser', JSON.stringify(user));
          window.location.reload();
          closePopup();
          generateNotification({
            content: `Konto zostało aktywowane`,
            type: NOTIFICATION_ENUM.SUCCESS,
            time: 4000,
          });
        } else {
          generateNotification({
            content: `Kod jest nieprawidłowy lub wygasł`,
            type: NOTIFICATION_ENUM.ERROR,
            time: 4000,
          });
        }
      });
    }
  }, [values]);

  const handleInputChange = (event, index) => {
    const newValues = [...values];
    newValues[index] = event.target.value;
    setValues(newValues);

    if (event.target.value && index < length - 1) {
      inputsRef.current[index + 1].focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && !values[index] && index > 0) {
      const newValues = [...values];
      newValues[index - 1] = '';
      setValues(newValues);
      inputsRef.current[index - 1].focus();
    }
  };
  useEffect(() => {
    authenticationService.resendCode().then((r) => {
      if (!r.ok) {
        closePopup();
      }
    });
  }, []);
  const { closePopup, clearIntervalManually } = usePopup();
  const { generateNotification } = useNotification();
  const handleResendCode = () => {
    authenticationService.resendCode().then((r) => {
      if (r.ok) {
        generateNotification({ type: 'success', time: 1500, content: 'Wysłano kod aktywacyjny ponownie.' });
      }
    });
  };
  const handleClose = (e) => {
    e.preventDefault();
    closePopup();
    clearIntervalManually();
  };

  return (
    <div className='activate-popup'>
      <div className='close-button'>
        <CloseIcon
          style={{ cursor: 'pointer' }}
          onClick={(e) => handleClose(e)}
        />
      </div>
      <span className='popup-title'>Wysłaliśmy kod aktywacyjny na adres użyty podczas rejestracji.</span>
      <div className='pin-container'>
        {Array.from({ length }, (_, i) => (
          <input
            key={i}
            ref={(el) => (inputsRef.current[i] = el)}
            className='pin'
            style={{
              width: '25px',
              height: '40px',
              margin: '0 5px',
              textAlign: 'center',
              fontSize: '30px',
              backgroundColor: 'transparent',
              borderBottom: values[i] ? 'none' : '2px solid black',
            }}
            maxLength={1}
            value={values[i]}
            onChange={(e) => handleInputChange(e, i)}
            onKeyDown={(e) => handleKeyDown(e, i)}
            pattern='\d*'
          />
        ))}
      </div>
      <p className='pin-info'>(Kod PIN ważny jest przez 5 min)</p>
      <span className='not-delivered'>
        Kod nie dotarł?{' '}
        <span
          className='bold-text'
          onClick={handleResendCode}
        >
          Wyślij ponownie
        </span>
      </span>
    </div>
  );
};

export default ActivateAccountPopup;
