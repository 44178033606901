import React, { useEffect, useState } from 'react';
import PageWrapper from '../wrappers/PageWrapper';
import TableBox from '../elements/MessagesQueue/TableBox/TableBox';
import messagesService from '../../services/messages.service';
import { MESSAGE_DISPLAY_TYPE } from '../../helpers/ENUMS';
import { useLocation, useNavigate } from 'react-router-dom';
import Pagination from '../elements/Contacts/TableBox/elements/Pagination';
import { ReactComponent as ArrowBack } from '../../assets/arrow.svg';

const MessagesQueue = () => {
  const [messageDisplayType, setMessageDisplayType] = useState(MESSAGE_DISPLAY_TYPE.SERVED);
  const [noServedMessagesData, setNoServedMessagesData] = useState({});
  const [servedMessagesData, setServedMessagesData] = useState({});
  const [recivedMessagesData, setRecivedMessagesData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [nextPageAvaible, setNextPageAvaible] = useState(null);
  const [prevPageAvaible, setprevPageAvaible] = useState(null);
  const [pageAmmount, setPageAmmount] = useState();

  const page_size = 100;
  const location = useLocation();
  const navigate = useNavigate();
  const id = extractId(location.search);
  const goToNextPage = () => {
    if (nextPageAvaible) {
      setPage(page + 1);
      document.getElementById('queue-list').scrollIntoView();
      document.getElementById('queue-list').scrollTo(0, 0);
    }
  };

  const goToPrevPage = () => {
    if (prevPageAvaible) {
      setPage(page - 1);
      document.getElementById('queue-list').scrollIntoView();
      document.getElementById('queue-list').scrollTo(0, 0);
    }
  };

  useEffect(() => {
    switch (messageDisplayType) {
      case MESSAGE_DISPLAY_TYPE.NO_SERVED:
        if (id !== null) {
          messagesService.getQueueSmsById({ page, is_served: false, id }).then((r) => {
            if (r.results) {
              setNoServedMessagesData(r);
              setNextPageAvaible(r.next);
              setprevPageAvaible(r.previous);
              setPageAmmount(Math.ceil(r.count / page_size));
            }
            setIsLoading(false);
          });
        } else {
          messagesService.getQueueSms({ page, is_served: false }).then((r) => {
            if (r.results) {
              setNoServedMessagesData(r);
              setNextPageAvaible(r.next);
              setprevPageAvaible(r.previous);
              setPageAmmount(Math.ceil(r.count / page_size));
            }
            setIsLoading(false);
          });
        }
        break;
      case MESSAGE_DISPLAY_TYPE.SERVED:
        if (id !== null) {
          messagesService.getQueueSmsById({ page, is_served: true, id }).then((r) => {
            if (r.results) {
              setServedMessagesData(r);
              setNextPageAvaible(r.next);
              setprevPageAvaible(r.previous);
              setPageAmmount(Math.ceil(r.count / page_size));
            }
            setIsLoading(false);
          });
        } else {
          messagesService.getQueueSms({ page, is_served: true }).then((r) => {
            if (r.results) {
              setServedMessagesData(r);
              setNextPageAvaible(r.next);
              setprevPageAvaible(r.previous);
              setPageAmmount(Math.ceil(r.count / page_size));
            }
            setIsLoading(false);
          });
        }
        break;
      case MESSAGE_DISPLAY_TYPE.RECIVED:
        if (id !== null) {
          messagesService.getReceivedSmsById({ page, id }).then((r) => {
            if (r.results) {
              setRecivedMessagesData(r);
              setNextPageAvaible(r.next);
              setprevPageAvaible(r.previous);
              setPageAmmount(Math.ceil(r.count / page_size));
            }
            setIsLoading(false);
          });
        } else {
          messagesService.getRecivedSms({ page }).then((r) => {
            if (r.results) {
              setRecivedMessagesData(r);
              setNextPageAvaible(r.next);
              setprevPageAvaible(r.previous);
              setPageAmmount(Math.ceil(r.count / page_size));
            }
            setIsLoading(false);
          });
        }
        break;
    }
  }, [messageDisplayType, page]);
  function extractId(queryString) {
    const params = new URLSearchParams(queryString);
    return params.get('id');
  }
  const handleAlignment = (event, newAlignment) => {
    setIsLoading(true);
    setPage(1);
    if (newAlignment !== null) {
      setMessageDisplayType(newAlignment);
    }
  };

  const returnArrayByCurrentType = (type) => {
    switch (type) {
      case MESSAGE_DISPLAY_TYPE.NO_SERVED:
        return noServedMessagesData?.results || [];
      case MESSAGE_DISPLAY_TYPE.SERVED:
        return servedMessagesData?.results || [];
      case MESSAGE_DISPLAY_TYPE.RECIVED:
        return recivedMessagesData?.results || [];
    }
  };

  return (
    <PageWrapper>
      <div className='messages-queue-page-wrapper'>
        <div
          className={`messages-queue-header ${!id ? 'active' : null}`}
          style={{ width: returnArrayByCurrentType(messageDisplayType).length > 0 ? 'calc(100% + 15px)' : '100%' }}
        >
          {id ? (
            <ArrowBack
              onClick={() => navigate('/messagesQueue')}
              style={{ cursor: 'pointer' }}
            />
          ) : null}
          <div className='filter-buttons'>
            <span
              className={`filter-button ${messageDisplayType === 'served' ? 'active' : null}`}
              onClick={(e) => handleAlignment(e, MESSAGE_DISPLAY_TYPE.SERVED)}
            >
              Wysłane
            </span>
            <span
              className={`filter-button ${messageDisplayType === 'selected' ? 'active' : null}`}
              onClick={(e) => handleAlignment(e, MESSAGE_DISPLAY_TYPE.NO_SERVED)}
            >
              Niewysłane
            </span>
            <span
              className={`filter-button ${messageDisplayType === 'group' ? 'active' : null}`}
              onClick={(e) => handleAlignment(e, MESSAGE_DISPLAY_TYPE.RECIVED)}
            >
              Doręczone
            </span>
          </div>
          {id ? <div className='blank' /> : null}
        </div>
        {!isLoading && (
          <TableBox
            data={returnArrayByCurrentType(messageDisplayType)}
            page={page}
          />
        )}
        {nextPageAvaible && (
          <Pagination
            pageAmmount={pageAmmount}
            page={page}
            nextPageAvaible={nextPageAvaible}
            goToNextPage={goToNextPage}
            goToPrevPage={goToPrevPage}
            prevPageAvaible={prevPageAvaible}
          />
        )}
      </div>
    </PageWrapper>
  );
};

export default MessagesQueue;
