import React from 'react';
import { Grid } from '@mui/material';
import GreenButton from '../../Global/GreenButton';
import messagesService from '../../../services/messages.service';

const SingleFile = ({ index, file, handleFetchAddedFiles }) => {
  const handleAccept = () => {
    messagesService.acceptShipment(file.id).then((response) => {
      console.log(response);
      handleFetchAddedFiles();
    });
  };
  return (
    <>
      <Grid
        container
        className='pc-single-file'
      >
        <Grid
          xs={0.2}
          item
          className='grid-item'
        >
          <span className='file-number'>{index}.</span>
        </Grid>
        <Grid
          xs={3}
          item
          className='grid-item'
        >
          <span className='file-name'>{file.file_name}</span>
        </Grid>
        <Grid
          xs={3}
          item
          className='file-queue'
        >
          {file.status === 'new' || file.status === 'ongoing' ? (
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <span className='loader'></span>
              <span className='file-status'>{file.status_display}</span>
            </div>
          ) : (
            <span className='file-status'>{file.status_display}</span>
          )}
        </Grid>
        <Grid
          xs={3}
          item
        >
          {file.status === 'accepted' && (
            <a
              href={`/messagesQueue?id=${file.id}`}
              className='show-queue'
            >
              Wyświetl zaimportowane wiadomości
            </a>
          )}
        </Grid>
        <Grid
          xs={2.8}
          item
          className='statistics'
        >
          {file.status === 'awaiting_acceptance' && <GreenButton handleClick={handleAccept}>Zaakceptuj do wysyłki</GreenButton>}
        </Grid>
      </Grid>
      <div className='mobile-single-file'>
        <div className='file-title'>
          <span className='file-name'>
            <span className='file-number'>{index}.</span>
            {file.file_name}
          </span>
        </div>
        <div className='file-queue'>
          <span className='file-amount'> wiadomości</span>
          <a
            href='#'
            className='show-queue'
          >
            Wyświetl kolejkę
          </a>
        </div>
        <div className='statistics'>
          <GreenButton noMargin>Zaakceptuj do wysyłki</GreenButton>
        </div>
      </div>
    </>
  );
};

export default SingleFile;
