import authHeader from '../helpers/auth-header';
import handleResponse from '../helpers/handle-response';
import config from '../config';

// const createDevice = async ({ isActive, throttleIn, throttleOut, deviceGroup }) => {
const createDevice = async () => {
  const auth = authHeader();

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
    body: JSON.stringify({
      is_active: true,
      throttle_in: 111,
      throttle_out: 111,
      // device_group: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    }),
  };

  return fetch(`${config.apiUrl}api-front/v1/device/`, requestOptions)
    .then((r) => handleResponse(r, requestOptions))
    .then((r) => {
      return r.json();
    })
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};

const getDeviceList = async () => {
  const auth = authHeader();

  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
  };

  return fetch(`${config.apiUrl}api-front/v1/device/?page=1`, requestOptions)
    .then((r) => handleResponse(r, requestOptions))
    .then((r) => {
      return r.json();
    })
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};

const generatePin = async ({ deviceId }) => {
  const auth = authHeader();

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
    body: JSON.stringify({
      is_active: true,
      throttle_in: 111,
      throttle_out: 111,
      // device_group: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    }),
  };

  return fetch(`${config.apiUrl}api-front/v1/device/${deviceId}/generate-pin/`, requestOptions)
    .then((r) => handleResponse(r, requestOptions))
    .then((r) => {
      return r.json();
    })
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};

const deactivateDevice = (id) => {
  const auth = authHeader();

  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
  };

  return fetch(`${config.apiUrl}api-front/v1/device/${id}`, requestOptions)
    .then((r) => handleResponse(r, requestOptions))
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};
export default { createDevice, generatePin, getDeviceList, deactivateDevice };
