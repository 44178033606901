import React from 'react';

const Checkbox = ({ checked, onChange, error, smaller }) => {
  return (
    <label
      className={`checkbox-label ${smaller ? 'smaller' : ''}`}
      style={{ display: 'block', borderColor: error ? 'red' : '#555555' }}
    >
      <input
        className='checkbox'
        type='checkbox'
        checked={checked}
        onChange={onChange}
      />
      <div
        style={checked ? { display: 'block', borderColor: 'black' } : {}}
        className='checked_div'
      />
    </label>
  );
};

export default Checkbox;
