import { Routes, Route, Navigate } from 'react-router-dom';
import React from 'react';
import routerList from './routerList';
import RouteWrapper from './components/elements/RouteWrapper';

const routes = (
  <Routes>
    {routerList.map(({ path, component }) => {
      return (
        <Route
          key={`${path}`}
          path={path}
          element={<RouteWrapper>{component}</RouteWrapper>}
        />
      );
    })}
    <Route
      path='*'
      element={
        <Navigate
          to='/login'
          replace={true}
        />
      }
    />
  </Routes>
);

export default routes;
