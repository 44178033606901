import React, { useState, useRef, useEffect } from 'react';
import Header from '../elements/pageWrapper/Header/Header';
import Sidebar from '../elements/pageWrapper/Sidebar/Sidebar';
import Popup from '../Global/popup/Popup';
import authenticationService from '../../services/authentication.service';
import { Context } from '../../Context';
import { useContextSelector } from 'use-context-selector';
import CircularProgress from '@mui/material/CircularProgress';

const MD_BRAKPOINT = 1000;

// eslint-disable-next-line react/prop-types
const PageWrapper = ({ children, background }) => {
  const [isActiveMenu, setDisplayMenu] = useState(false);
  const { current } = useRef(window.innerWidth);
  const { isAuth, setIsAuth } = useContextSelector(Context, (state) => state);
  let is_active = JSON.parse(localStorage.getItem('currentUser')).is_activated;
  useEffect(() => {
    if (isActiveMenu && current < MD_BRAKPOINT) {
      document.body.classList.add('fixed-position');
    }
    if (!isActiveMenu && current < MD_BRAKPOINT) {
      document.body.classList.remove('fixed-position');
    }
  }, [isActiveMenu]);

  useEffect(() => {
    if (!isAuth) {
      let user = JSON.parse(localStorage.getItem('currentUser'));
      if (!user) {
        authenticationService.logout(true);
      } else {
        setIsAuth(true);
      }
    }
  }, []);
  if (isAuth) {
    return (
      <div className='page_wrapper'>
        <Header
          setDisplayMenu={setDisplayMenu}
          isActiveMenu={isActiveMenu}
        />
        {(isActiveMenu || current > MD_BRAKPOINT) && (
          <Sidebar
            setDisplayMenu={setDisplayMenu}
            isActiveMenu={isActiveMenu}
          />
        )}
        <Popup />
        <div
          className={is_active ? 'main-wrapper-lowered main-wrapper' : 'main-wrapper'}
          style={{ background: `${background || ''}` }}
        >
          <div className='page-content-wrapper'>{children}</div>
        </div>
      </div>
    );
  }
  return <CircularProgress />;
};

export default PageWrapper;
