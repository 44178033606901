import React from 'react';
import SingleDeviceSlot from './SingleDeviceSlot';
import { Grid } from '@mui/material';
import MobileSingleDeviceSlot from './MobileSingleDeviceSlot';

const DeviceSlotList = ({ deviceList, checkDeviceStatus }) => {
  return (
    <>
      <div className='table-names'>
        {window.screen.width > 1000 ? (
          <Grid
            container
            spacing={0}
            style={{ marginBottom: '30px' }}
          >
            <Grid sm={3}>
              <span className='table-name-text'>Nazwa</span>
            </Grid>
            <Grid sm={3}>
              <span className='table-name-text'>Status</span>
            </Grid>
            <Grid sm={4}>
              <span className='table-name-text'>Statystyki</span>
            </Grid>
            <Grid sm={2}></Grid>
          </Grid>
        ) : null}
        <ul className='device-list'>
          {window.screen.width > 1000
            ? deviceList.map((device, index) => {
                return (
                  <SingleDeviceSlot
                    slotNumber={index + 1}
                    deviceId={device.id}
                    first_connection={device.first_connection}
                    key={index}
                    active={device.is_active}
                    pin={device.pin}
                    checkDeviceStatus={checkDeviceStatus}
                    last_24h_stats={device.last_24h_stats}
                    last_7d_stats={device.last_7d_stats}
                    last_30d_stats={device.last_30d_stats}
                  />
                );
              })
            : deviceList.map((device, index) => {
                return (
                  <MobileSingleDeviceSlot
                    slotNumber={index + 1}
                    deviceId={device.id}
                    first_connection={device.first_connection}
                    key={index}
                    active={device.is_active}
                    pin={device.pin}
                    checkDeviceStatus={checkDeviceStatus}
                    last_24h_stats={device.last_24h_stats}
                    last_7d_stats={device.last_7d_stats}
                    last_30d_stats={device.last_30d_stats}
                  />
                );
              })}
        </ul>
        {/*<span*/}
        {/*  className='add-new-device'*/}
        {/*  onClick={(e) => createDevice(e)}*/}
        {/*>*/}
        {/*  Dodaj nowe urządzenie*/}
        {/*</span>*/}
      </div>
    </>
  );
};

export default DeviceSlotList;
