import React from 'react';
import TableRow from './elements/TableRow';
import { Grid } from '@mui/material';

const TableBox = ({ data, page }) => {
  return (
    <div
      className='table-box-wrapper'
      id='queue-list'
    >
      <Grid
        container
        className='grid-container'
      >
        <Grid
          item
          xs={0.5}
        ></Grid>
        <Grid
          item
          xs={2}
        >
          <span className='table-h'>ID wiadomości</span>
        </Grid>
        <Grid
          item
          xs={1.6}
        >
          <span className='table-h'>Odbiorca</span>
        </Grid>
        <Grid
          item
          xs={2}
        >
          <span className='table-h'>Status</span>
        </Grid>
        <Grid
          item
          xs={2}
        >
          <span className='table-h'>Treść</span>
        </Grid>
        <Grid
          item
          xs={1.8}
        >
          <span className='table-h'>Data wysyłki</span>
        </Grid>
        <Grid
          item
          xs={1.8}
        >
          <span className='table-h'>Planowana wysyłka</span>
        </Grid>
        <Grid
          item
          xs={0.3}
        />
      </Grid>
      {data.length === 0 ? (
        <div className='no-messages'>
          <span>Brak wiadomości</span>
        </div>
      ) : (
        <ul className='messages-list'>
          {data.map((message, index) => {
            return (
              <li
                className='message-item'
                key={index}
              >
                <TableRow
                  id={(page - 1) * 100 + index + 1}
                  msg={message.msg}
                  message_id={message.id}
                  status_display={message.status_display}
                  recipient={message.recipient_phone}
                  schedule_timestamp={message.schedule_timestamp}
                  sent_timestamp={message.served_timestamp}
                />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default TableBox;
