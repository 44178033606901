import React from 'react';
import TarrifBox from '../../Global/TarrifBox';
import tarrifService from '../../../services/tarrif.service';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as Close } from '../../../assets/chip_close.svg';
const Options = ({ setShowOptions }) => {
  const [tarrifs, setTarrifs] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    tarrifService.getTariffs().then((r) => {
      if (Array.isArray(r.results)) {
        setIsLoading(false);
        setTarrifs([r.results, r.results, r.results]);
      }
    });
  }, []);
  return (
    <>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Close
          width={12}
          height={12}
          onClick={() => setShowOptions(false)}
          style={{ cursor: 'pointer' }}
        />
      </div>
      <h2 className='main-title-shop'>Zacznij komfortową wysyłkę wiadomości SMS</h2>
      <div className='shop-page-wrapper'>
        <div className='pricing-options'>
          <div className='list-wrapper price-list'>
            <div className='list-title pricing'></div>
            <ul className='offer-list offer-options'>
              <div>
                <li>
                  <p className='key'>value</p>
                  <p className='value'>Pakiet wiadomości</p>
                </li>
              </div>
              <div>
                <li>
                  <p className='key'>value</p>
                  <p className='value'>Maksymalna liczba urządzeń</p>
                </li>
              </div>
              <div>
                <li>
                  <p className='key'>value</p>
                  <p className='value'>Wysyłka masowa</p>
                </li>
              </div>
              <div>
                <li>
                  <p className='key'>value</p>
                  <p className='value'>Odbieranie wiadomości</p>
                </li>
              </div>
              <div>
                <li>
                  <p className='key'>value</p>
                  <p className='value'>Historia wiadomości</p>
                </li>
              </div>
              <div>
                <li>
                  <p className='key'>value</p>
                  <p className='value'>Planowanie wysyłki</p>
                </li>
              </div>
              <div>
                <li>
                  <p className='key'>value</p>
                  <p className='value'>Obsługa plików CSV</p>
                </li>
              </div>
            </ul>
          </div>
          {!isLoading ? (
            tarrifs.map((tarrif, index) => {
              return (
                <TarrifBox
                  key={tarrif.id || index}
                  isPromote={index == 1}
                  tarrif={tarrif}
                  avaibleSelect
                />
              );
            })
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>
    </>
  );
};

export default Options;
