import React from 'react';
import { ReactComponent as Info } from '../../assets/images/information.svg';

import usePopup from '../../helpers/usePopup';
import ActivateAccountPopup from './popup/variants/ActivateAccountPopup';

const ActivatePopup = () => {
  const { initPopup } = usePopup();
  let is_active = JSON.parse(localStorage.getItem('currentUser')).is_activated;
  const handleActivateAccount = () => {
    initPopup(<ActivateAccountPopup />);
  };
  return (
    !is_active && (
      <div className='active-account-container'>
        <div className='active-account'>
          <Info />
          <p className='activate-text'>
            <span
              className='bold-text'
              onClick={handleActivateAccount}
            >
              Aktywuj konto tutaj
            </span>
            , aby w pełni korzystać z możliwości serwisu.
          </p>
        </div>
      </div>
    )
  );
};

export default ActivatePopup;
