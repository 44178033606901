import React from 'react';

const MobileMenuIcon = ({ setDisplayMenu, isActiveMenu }) => {
  return (
    <div className='all-btn'>
      <div
        className={`menu-btn-2 ${isActiveMenu ? 'active' : ''}`}
        onClick={() => setDisplayMenu(!isActiveMenu)}
      >
        <span></span>
      </div>
    </div>
  );
};

export default MobileMenuIcon;
