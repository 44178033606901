const RoutesPaths = {
  Home: {
    path: '/home',
    route: () => '/',
  },
  Login: {
    path: '/login',
    route: () => '/',
  },
  Register: {
    path: '/register',
    route: () => '/',
  },
  Reset: {
    path: '/reset',
    route: () => '/',
  },
  Contact: {
    path: '/contact',
    route: () => '/',
  },
  Messages: {
    path: '/messages',
    route: () => '/',
  },
  Import: {
    path: '/import',
    route: () => '/',
  },
  Settings: {
    path: '/settings',
    route: () => '/',
  },
  Help: {
    path: '/help',
    route: () => '/',
  },
  MessagesQueue: {
    path: '/messagesQueue',
    route: () => '/',
  },
  // robocze:
  Profile: {
    path: '/myProfile',
    route: () => '/',
  },
  Shop: {
    path: '/shop',
    route: () => '/',
  },
  PaymentSummary: {
    path: '/paymentSummary',
    route: () => '/',
  },

  PasswordReset: {
    path: '/passwordReset',
    route: () => '/',
  },
};

export default RoutesPaths;
