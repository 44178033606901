import React from 'react';

const Pagination = ({ page, nextPageAvaible, goToNextPage, prevPageAvaible, goToPrevPage, pageAmmount }) => {
  return (
    <div className='pagination'>
      <button
        onClick={() => goToPrevPage()}
        disabled={prevPageAvaible ? false : true}
      >
        {'<'}
      </button>
      <button
        onClick={() => goToNextPage()}
        disabled={nextPageAvaible ? false : true}
      >
        {'>'}
      </button>
      Widok {page} z {pageAmmount}
    </div>
  );
};

export default Pagination;
