import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TitleUrlList from './TitleUrlList';
import ActivatePopup from '../../../Global/ActivatePopup';
import config from '../../../../config';

const TitleWrapperComponent = () => {
  const location = useLocation();
  useEffect(() => {
    let title = `${config.appTitle} | ${TitleUrlList.find((element) => element.url === location.pathname).component.props.children}`;
    document.title = title;
    document.querySelector('meta[property="og:title"]').setAttribute('content', title);
    document.querySelector('meta[property="twitter:title"]').setAttribute('content', title);
  });
  return (
    <div className='header-elements-container'>
      <div className='header-titles-wrapper'>{TitleUrlList.find((element) => element.url === location.pathname).component}</div>
      {window.screen.width > 1000 && (
        <div className='pc-activate'>
          <ActivatePopup />
        </div>
      )}
    </div>
  );
};

export default TitleWrapperComponent;
