import React from 'react';

const Row = ({ invoice, index }) => {
  const [showButton, setShowButton] = React.useState(false);
  return (
    <tr>
      <th className='index'>{index}.</th>
      <th className='date'>{new Date(invoice.created_at).toLocaleDateString()}</th>
      <th className='invoice'>f/1346/2445/3</th>
      <th className='amount'>{invoice.amount}zł</th>
      <th className='status'>{invoice.payment_status}</th>
      <th className='download'>
        <span
          className='dots'
          onClick={() => setShowButton((prevState) => !prevState)}
        >
          ...
        </span>
        {showButton && <button className='download-button'>Pobierz fakturę</button>}
      </th>
    </tr>
  );
};

export default Row;
