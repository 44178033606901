import authHeader from '../helpers/auth-header';
import handleResponse from '../helpers/handle-response';
import config from '../config';

const getTariffs = async () => {
  const auth = authHeader();

  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
  };

  return fetch(`${config.apiUrl}api-front/v1/tariff/?country=1&page=1`, requestOptions)
    .then((r) => handleResponse(r, requestOptions))
    .then((r) => {
      return r.json();
    })
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};

export default { getTariffs };
