import authHeader from '../helpers/auth-header';
import handleResponse from '../helpers/handle-response';
import config from '../config';
export const handleFetchBasicData = async () => {
  const user = JSON.parse(localStorage.getItem('currentUser'));
  console.log(user);
  const auth = authHeader();
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
  };
  return fetch(`${config.apiUrl}api-front/v1/user/${user.user_id}`, requestOptions).then((r) => handleResponse(r, requestOptions));
};

export const handleFetchCountriesList = async () => {
  const auth = authHeader();
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
  };
  return fetch(`${config.apiUrl}api-front/v1/country/`, requestOptions).then((r) => handleResponse(r, requestOptions));
};
export const handlePatchInvoiceData = async (form) => {
  const auth = authHeader();
  const user = JSON.parse(localStorage.getItem('currentUser'));
  console.log('FORM:', form);
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
    body: JSON.stringify(form),
  };
  return fetch(`${config.apiUrl}api-front/v1/organization/${user.organization_id}/`, requestOptions).then((r) => handleResponse(r, requestOptions));
};
export const handleFetchInvoiceData = async () => {
  const auth = authHeader();
  const user = JSON.parse(localStorage.getItem('currentUser'));
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
  };
  return fetch(`${config.apiUrl}api-front/v1/organization/${user.organization_id}`, requestOptions).then((r) => handleResponse(r, requestOptions));
};
export const handlePostNewPassword = async (old, new_password) => {
  const auth = authHeader();
  const user = JSON.parse(localStorage.getItem('currentUser'));
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
    body: JSON.stringify({
      user_id: user.user_id,
      password: old,
      new_password,
    }),
  };
  return fetch(`${config.apiUrl}api-front/v1/user/change-password/`, requestOptions).then((r) => handleResponse(r, requestOptions));
};
