import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    width: 250,
    '&::placeholder': {
      fontStyle: 'italic',
      color: '#555555',
      fontWeight: '400',
      fontFamily: 'Montserrat',
      fontSize: '14px',
    },
  },
}));

const Search = styled('div')(({ theme }) => ({
  border: '0.5px solid #000',
  position: 'relative',
  borderRadius: '5px',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const SearchStyled = ({ value, handleFunction }) => {
  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        value={value}
        onChange={(e) => handleFunction(e)}
        placeholder='Wyszukaj kontakt'
        inputProps={{ 'aria-label': 'search' }}
      />
    </Search>
  );
};

export default SearchStyled;
