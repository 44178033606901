import React from 'react';
import TableRow from './elements/TableRow';
import { Grid } from '@mui/material';

const TableBox = ({ contactList, fetchContacts }) => {
  return (
    <div className='table-box-wrapper'>
      <Grid container>
        <Grid
          item
          xs={0.8}
        ></Grid>
        <Grid
          item
          xs={2.9}
        >
          <span className='contacts-header'>Imię</span>
        </Grid>
        <Grid
          item
          xs={2.3}
        >
          <span className='contacts-header'>Numer telefonu</span>
        </Grid>
        <Grid
          item
          xs={5.3}
        >
          <span className='contacts-header'>Wysłanych wiadomości</span>
        </Grid>
        <Grid
          item
          xs={0.7}
        ></Grid>
      </Grid>
      <ul className='contacts-list'>
        {contactList.map((contact, index) => {
          return (
            <TableRow
              user={contact}
              key={index}
              fetchContacts={fetchContacts}
            />
          );
        })}
      </ul>
      {contactList.length === 0 && (
        <div className='empty-contacts'>
          <span className='empty-contacts-title'>Brak kontaktów</span>
        </div>
      )}
    </div>
  );
};

export default TableBox;
