import React from 'react';
import { ReactComponent as Triangle } from '../../assets/accordion-triangle.svg';
const Question = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div
      className={`accordion-wrapper ${isOpen ? 'open' : ''}`}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className='accordion-header-wrapper'>
        <h2 className='accordion-header'>Dlaczego nie mogę aktywować mojego urządzienia?</h2>
        <div className='accordion-triangle' />
        <Triangle className='accordion-triangle' />
      </div>
      <p className='question-answer'>
        ed ut lectus in orci iaculis mattis a vel velit. Nullam at justo ac erat feugiat iaculis at id nunc. Vestibulum ut luctus massa. Maecenas
        iaculis vitae libero non tristique. Suspendisse ultricies blandit mauris sed elementum. Suspendisse molestie metus id iaculis laoreet. Fusce
        mauris nibh, molestie ut placerat non, vestibulum sed dolor. Maecenas dignissim ligula id scelerisque tincidunt. Morbi commodo sem mi,
        placerat ullamcorper ligula pharetra viverra.
      </p>
    </div>
  );
};

export default Question;
