import React, { useEffect, useState } from 'react';
import { CssTextField } from '../../scss/settings/StyledMUIComponents';
import GreenButton from '../Global/GreenButton';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import authenticationService from '../../services/authentication.service';
// import RoutesPaths from '../../RoutesPaths';
import useNotification from '../../helpers/notifications/notificationGenerator';
import { ReactComponent as Close } from '../../assets/close.svg';
import { useNavigate } from 'react-router-dom';
import config from '../../config';

/* eslint-disable no-unused-vars */
const Reset = () => {
  const [form, setForm] = useState({ email: '', password: '', repeat: '', captcha: '' });
  const [captchaKey, setCaptchaKey] = useState(0);
  const [error, setError] = useState({});
  const [fromLink, setFromLink] = useState(false);
  const { generateNotification } = useNotification();
  const navigate = useNavigate();
  useEffect(() => {
    document.title = `${config.appTitle} | Resetowanie hasła`;
  });
  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  const validatePassword = (password) => {
    const lengthCheck = password.length >= 8;
    const digitCheck = /\d/.test(password); // Sprawdza, czy jest cyfra
    const specialCharCheck = /[!@#$%^&*(),.?":{}|<>]/.test(password); // Sprawdza, czy jest znak specjalny

    return lengthCheck && digitCheck && specialCharCheck;
  };
  const validateLoginData = () => {
    let error = false;
    if (fromLink) {
      if (!form.password) {
        setError((prevState) => {
          return { ...prevState, password: 'Uzupełnij pole' };
        });
        error = true;
      }
      if (!form.repeat) {
        setError((prevState) => {
          return { ...prevState, repeat: 'Uzupełnij pole' };
        });
        error = true;
      }
      if (form.captcha.length <= 0) {
        setError((prevState) => {
          return { ...prevState, captcha: 'Rozwiąż Captche' };
        });
        error = true;
      }
    } else {
      if (!form.email) {
        setError((prevState) => {
          return { ...prevState, email: 'Uzupełnij pole' };
        });
        error = true;
      } else {
        if (!validateEmail(form.email)) {
          setError((prevState) => {
            return { ...prevState, email: 'Niepoprawny adres email' };
          });
          error = true;
        }
      }
      if (form.captcha.length <= 0) {
        setError((prevState) => {
          return { ...prevState, captcha: 'Rozwiąż Captche' };
        });
        error = true;
      }
    }
    return error;
  };
  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
    setError({});

    if (form.captcha.length > 0) {
      setCaptchaKey((prevKey) => prevKey + 1);
      setForm((prevState) => {
        return { ...prevState, captcha: '' };
      });
    }
  };
  const sendResetCode = (e) => {
    e.preventDefault();
    console.log('Ide');
    if (validateLoginData()) {
      return;
    }
    authenticationService
      .resetPassword(form)
      .then(async (r) => {
        if (!r.ok) {
          await r.json();
          if (r.errors[0].code === 'no_active_account') {
            setError({ detail: 'Konto o podanych danych nie istnieje' });
          }
        } else {
          generateNotification({ type: 'success', content: 'Wysłano link do resetowania hasła', time: 4000 });
        }
        // if (r.refresh) {
        //     navigate(RoutesPaths.Home.path);
        // }
      })
      .catch((r) => {
        console.log(r);
      });
    setCaptchaKey((prevKey) => prevKey + 1);
  };

  return (
    <main className='reset-page'>
      <div className='close-button'>
        <Close
          style={{ cursor: 'pointer' }}
          onClick={() => navigate('/login')}
        />
      </div>
      <div className='send-email-container'>
        {fromLink ? (
          <>
            <h1 className='reset-header'>Nadaj nowe hasło</h1>
            <p className='reset-text'>
              Pamiętaj, że hasło powinno składać się z min. 8 znaków i zawierać przynakmniej jedną WIELKĄ literę oraz cyfrę.
            </p>
            <CssTextField
              id='outlined-required'
              label='Nowe hasło'
              fullWidth
              type='password'
              value={form.password}
              error={error.password}
              name='password'
              sx={{
                width: 360,
                margin: '10px 0',
                '@media(max-width:510px)': {
                  width: '100%',
                },
              }}
              onChange={handleChange}
              hasValue={form.password?.length > 0}
              inputProps={{
                autocomplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
              }}
            />
            {error?.password && <p className='register-error'>{error?.password ? error.password : ' '}</p>}
            <CssTextField
              id='outlined-required'
              label='Potwórz nowe hasło'
              fullWidth
              type='password'
              value={form.repeat}
              name='repeat'
              error={error.repeat}
              sx={{
                width: 250,
                margin: '10px 0',
                marginBottom: '40px',
              }}
              onChange={handleChange}
              hasValue={form.repeat?.length > 0}
              inputProps={{
                autocomplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
              }}
            />
            {error?.repeat && <p className='register-error'>{error?.repeat ? error.repeat : ' '}</p>}
            <div className={form.password.length > 0 && form.repeat.length > 0 ? 'captcha-toleft-container' : 'captcha-none'}>
              <HCaptcha
                sitekey={config.captchaSitekey}
                key={captchaKey}
                onVerify={(token) =>
                  setForm((prevState) => {
                    return { ...prevState, captcha: token };
                  })
                }
              />
              {error?.captcha && <p className='register-error'>{error?.captcha ? error.captcha : ' '}</p>}
            </div>
            <GreenButton>Potwierdź</GreenButton>
          </>
        ) : (
          <form onSubmit={sendResetCode}>
            <h1 className='reset-header'>Reset hasła</h1>
            <p className='reset-text'>Wpisz poniżej adres email połączony z Twoim kontem, a nastepnie sprawdź skrzynkę odbiorczą</p>
            <CssTextField
              id='outlined-required'
              label='E-mail'
              fullWidth
              value={form.email}
              error={error.email}
              name='email'
              sx={{
                width: 360,
                margin: '30px 0 40px 0',
                '@media(max-width:510px)': {
                  width: '100%',
                },
              }}
              onChange={handleChange}
              hasValue={form.email?.length > 0}
              inputProps={{
                autocomplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
              }}
            />
            {error?.email && <p className='register-error'>{error?.email ? error.email : ' '}</p>}
            <div className={form.email.length > 0 ? 'captcha-toleft-container' : 'captcha-none'}>
              <HCaptcha
                sitekey={config.captchaSitekey}
                key={captchaKey}
                onVerify={(token) =>
                  setForm((prevState) => {
                    return { ...prevState, captcha: token };
                  })
                }
              />
              {error?.captcha && <p className='register-error'>{error?.captcha ? error.captcha : ' '}</p>}
            </div>
            <GreenButton submit>Resetuj hasło</GreenButton>
          </form>
        )}
      </div>
    </main>
  );
};

export default Reset;
