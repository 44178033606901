import React from 'react';

const RedButton = ({ children, handleClick, noMargin }) => {
  return (
    <button
      onClick={(e) => handleClick(e)}
      className={!noMargin ? 'global-btn red-global-btn' : 'global-btn no-margin red-global-btn'}
    >
      {children}
    </button>
  );
};

export default RedButton;
