import React from 'react';
import PageWrapper from '../wrappers/PageWrapper';
import Question from '../Global/Question';
import { ReactComponent as Image } from '../../assets/OBJECT.svg';
const Help = () => {
  return (
    <PageWrapper>
      <div className='help-wrapper'>
        <h1 className='help-header'>Najczęściej zadawane pytania</h1>
        <div className='help-container'>
          <div className='questions'>
            <Question />
            <Question />
            <Question />
            <Question />
            <Question />
            <Question />
            <Question />
            <Question />
          </div>
          <Image className='help-image' />
        </div>
      </div>
    </PageWrapper>
  );
};

export default Help;
