import React from 'react'; // , { useRef }
import { useContextSelector } from 'use-context-selector';
import AddContactsPopUp from '../../Global/popup/variants/AddContactsPopUp';
import GreenButton from '../../Global/GreenButton';
import SearchStyled from '../../Global/Search';
import { Context } from '../../../Context';
// import contactService from '../../../services/contact.service';

const Header = ({ fetchContacts }) => {
  const popup = useContextSelector(Context, (state) => state.popup);
  const setPopup = useContextSelector(Context, (state) => state.setPopup);
  const { contactPageSearchData, setContactPageSearchData } = useContextSelector(Context, (state) => state);

  // const fileInput = useRef(null);

  // const handleImportCSV = (e) => {
  //   const file = e.target.files[0];
  //   let formData = new FormData();
  //   formData.append('contacts', file);
  //   if (formData) {
  //     contactService.importContacts(formData).then((res) => {
  //       if (res.ok) {
  //         fetchContacts();
  //         // Clear the file input after upload
  //         fileInput.current.value = '';
  //       }
  //     });
  //   }
  // };

  const handleTypeSearch = (e) => {
    setContactPageSearchData(e.target.value);
  };
  const handleAddContacts = (e) => {
    e.preventDefault();
    setPopup({ ...popup, display: true, component: <AddContactsPopUp fetchContacts={fetchContacts} /> });
  };

  return (
    <div className='header'>
      <div className='header-btn-container'>
        <GreenButton handleClick={handleAddContacts}>Dodaj kontakt</GreenButton>
        {/*<button*/}
        {/*  className='global-btn'*/}
        {/*  style={{ position: 'relative' }}*/}
        {/*>*/}
        {/*  Import kontaktów{' '}*/}
        {/*  <input*/}
        {/*    type='file'*/}
        {/*    onChange={handleImportCSV}*/}
        {/*    ref={fileInput}*/}
        {/*  />*/}
        {/*</button>*/}
      </div>
      <SearchStyled
        value={contactPageSearchData}
        handleFunction={(e) => handleTypeSearch(e)}
      />
    </div>
  );
};

export default Header;
