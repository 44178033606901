import React from 'react';
import authenticationService from '../../../../services/authentication.service';
import GreenButton from '../../GreenButton';
import usePopup from '../../../../helpers/usePopup';

const Logout = () => {
  const { closePopup } = usePopup();
  const handleLogout = (e) => {
    e.preventDefault();
    authenticationService.logout(true);
  };

  return (
    <div className='logout-container'>
      <p className='logout-title'>Czy napewno chcesz się wylogować?</p>
      <div className='logout-buttons'>
        <span
          className='back-to-app'
          onClick={closePopup}
        >
          Powrót do aplikacji
        </span>
        <GreenButton handleClick={handleLogout}>Wyloguj</GreenButton>
      </div>
    </div>
  );
};

export default Logout;
