import React from 'react';
import Popup from './Popup';
import { Grid } from '@mui/material';

const TableRow = ({ user, fetchContacts }) => {
  return (
    <li className='contacts-item'>
      <Grid container>
        <Grid
          item
          sm={0.8}
        >
          <div className='user-picture'>{user.first_name[0]}</div>
        </Grid>
        <Grid
          item
          sm={2.9}
        >
          <span className='user-info'>
            {' '}
            {user.first_name} {user.last_name}
          </span>
        </Grid>
        <Grid
          item
          sm={2.3}
        >
          <span className='user-info'>{user.phone_number}</span>
        </Grid>
        <Grid
          item
          sm={5.3}
        >
          <span className='user-info'>0</span>
        </Grid>
        <Grid
          item
          sm={0.7}
        >
          <div className='sended-messages-wrapper'>
            <Popup
              user={user}
              fetchContacts={fetchContacts}
            />
          </div>
        </Grid>
      </Grid>
    </li>
  );
};

export default TableRow;
