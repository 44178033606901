import React from 'react';
import GreenButton from '../../Global/GreenButton';
import usePopup from '../../../helpers/usePopup';

const SavePopup = () => {
  const { closePopup } = usePopup();
  const handleClose = () => {
    closePopup();
  };
  return (
    <div className='popup'>
      <span>Zmiany zostały zapisane</span>
      <GreenButton
        noMargin
        handleClick={handleClose}
      >
        OK
      </GreenButton>
    </div>
  );
};

export default SavePopup;
