import authenticationService from '../services/authentication.service';

async function handleResponse(response, requestOptions) {
  if ([401, 403].indexOf(response.status) !== -1) {
    const clonedResponse = response.clone();
    const body = await clonedResponse.json();
    if (body.errors.length > 0 && body.errors[0].code.toLowerCase().indexOf('token_not_valid') !== -1) {
      return authenticationService.refreshToken(response.url, requestOptions);
    }
    if (body.detail && body.detail.toLowerCase().indexOf('were not provided') !== -1) {
      return authenticationService.logout(true);
    }
  }

  return response;
}

export default handleResponse;
