import { useEffect, useState } from 'react';
import contactService from '../../../services/contact.service';

const useFilterLiveHook = ({ page_size }) => {
  const [filteredArray, setFilteredArray] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(1);
  const [name, setName] = useState();
  const [phone_number, setPhone_number] = useState();
  const [nextPageAvaible, setNextPageAvaible] = useState(null);
  const [prevPageAvaible, setprevPageAvaible] = useState(null);
  const [pageAmmount, setPageAmmount] = useState();

  useEffect(() => {
    setPage(1);
  }, [name, phone_number]);

  useEffect(() => {
    contactService
      .getContactList({ page, page_size, name, phone_number })
      .then((r) => {
        setFilteredArray([...r.results]);
        setIsLoading(false);
        setNextPageAvaible(r.next);
        setprevPageAvaible(r.previous);
        setPageAmmount(Math.ceil(r.count / page_size));
      })
      .catch((err) => setIsError(err));
  }, [name, phone_number, page]);
  const fetchContacts = () => {
    setIsLoading(true);
    contactService
      .getContactList({ page, page_size, name, phone_number })
      .then((r) => {
        setFilteredArray([...r.results]);
        setIsLoading(false);
        setNextPageAvaible(r.next);
        setprevPageAvaible(r.previous);
        setPageAmmount(Math.ceil(r.count / page_size));
      })
      .catch((err) => setIsError(err));
  };
  const goToNextPage = () => {
    if (nextPageAvaible) {
      setPage(page + 1);
    }
  };

  const goToPrevPage = () => {
    if (prevPageAvaible) {
      setPage(page - 1);
    }
  };

  return {
    filteredArray,
    isLoading,
    isError,
    setPhone_number,
    setName,
    setPage,
    page,
    goToNextPage,
    goToPrevPage,
    nextPageAvaible,
    prevPageAvaible,
    pageAmmount,
    fetchContacts,
  };
};

export default useFilterLiveHook;
