import Home from '../../../../assets/images/sidebarIcons/console.svg';
import Messages from '../../../../assets/images/sidebarIcons/message.svg';
import Import from '../../../../assets/images/sidebarIcons/import.svg';
import Queue from '../../../../assets/images/sidebarIcons/queue.svg';
import Contacts from '../../../../assets/images/sidebarIcons/contacts.svg';
import Settings from '../../../../assets/images/sidebarIcons/settings.svg';
import Payments from '../../../../assets/images/sidebarIcons/payments.svg';

import RoutesPaths from '../../../../RoutesPaths';
import MyAccount from '../../../../assets/images/sidebarIcons/account.svg';

const MenuList = [
  { title: 'Pulpit', image: Home, url: RoutesPaths.Home.path },
  { title: 'Wyślij wiadomość', image: Messages, url: RoutesPaths.Messages.path },
  { title: 'Import wiadomości', image: Import, url: RoutesPaths.Import.path },
  { title: 'Kolejka wiadomości', image: Queue, url: RoutesPaths.MessagesQueue.path },
  { title: 'Kontakty', image: Contacts, url: RoutesPaths.Contact.path },
  { title: 'Integracje', image: Settings, url: RoutesPaths.Settings.path },
  { title: 'Profil', image: MyAccount, url: RoutesPaths.Profile.path },
  { title: 'Pakiety', image: Payments, url: RoutesPaths.Shop.path },
];

export default MenuList;
