import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';

const TableRow = ({ id, message_id, status_display, msg, sent_timestamp, recipient, schedule_timestamp }) => {
  const [isHovered, setIsHovered] = useState(false);
  const messageRef = useRef(null);

  // Check if content overflows
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    // This checks if the content's height is greater than the max height of the container
    setIsOverflowing(messageRef.current.scrollHeight > messageRef.current.clientHeight);
  }, [msg]);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  // Adjust styles conditionally based on hovering and if content is overflowing
  let messageStyle = {};
  if (!isHovered && isOverflowing) {
    messageStyle = {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    };
  }
  return (
    <Grid
      container
      style={{ padding: '10px 0', borderBottom: '0.5px solid #DBDBDB' }}
    >
      <Grid
        item
        xs={0.5}
      >
        <span className='table-item'>{id}.</span>
      </Grid>
      <Grid
        item
        xs={2}
      >
        <span className='table-item'> {message_id}</span>
      </Grid>
      <Grid
        item
        xs={1.6}
      >
        <span className='table-item'>{recipient}</span>
      </Grid>
      <Grid
        item
        xs={2}
      >
        <span className='table-item'>{status_display}</span>
      </Grid>
      <Grid
        item
        xs={2}
      >
        <span
          ref={messageRef}
          className='table-item table-message'
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={messageStyle}
        >
          {msg}
        </span>
      </Grid>
      <Grid
        item
        xs={1.8}
      >
        <span className='table-item'>
          {sent_timestamp
            ? `${new Date(sent_timestamp).getDate().toString()}${'-'}${(new Date(sent_timestamp).getMonth() + 1).toString()}${'-'}${new Date(
                sent_timestamp,
              )
                .getFullYear()
                .toString()}`
            : '-'}
        </span>
      </Grid>
      <Grid
        item
        xs={1.8}
      >
        <span className='table-item'>
          {schedule_timestamp
            ? `${new Date(schedule_timestamp).getDate().toString()}${'-'}${(new Date(sent_timestamp).getMonth() + 1).toString()}${'-'}${new Date(
                schedule_timestamp,
              )
                .getFullYear()
                .toString()}`
            : '-'}
        </span>
      </Grid>
      <Grid
        item
        xs={0.3}
      >
        <button className='dots'>...</button>
      </Grid>
    </Grid>
  );
};

export default TableRow;
