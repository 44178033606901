import React, { useEffect } from 'react';
import GreenButton from '../../Global/GreenButton';
import Row from './Row';
import Payment from './Payment';
import SavedMethod from './SavedMethod';
import PaymentService from '../../../services/payment.service';
const MyOption = ({ setShowOptions, changedInvoiceData }) => {
  const [showPayment, setShowPayment] = React.useState(false);
  const [savedPaymentMethods, setSavedPaymentMethods] = React.useState([]);
  const [invoices, setInvoices] = React.useState([]);
  const handleDelete = (index) => {
    setSavedPaymentMethods((prevState) => {
      return prevState.filter((_, i) => i !== index);
    });
  };
  const handleFetchInvoices = async () => {
    await PaymentService.fetchInvoices()
      .then((data) => {
        setInvoices(data.results);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    handleFetchInvoices();
  }, []);
  return (
    <div className='page-wrapper'>
      {showPayment || changedInvoiceData ? (
        <Payment
          setSavedPaymentMethods={setSavedPaymentMethods}
          setShowPayment={setShowPayment}
          changedInvoiceData={changedInvoiceData}
        />
      ) : (
        <>
          <div className='detail-header-wrapper'>
            <h1 className='options-header'>Aktywny pakiet: Starter - darmowa wersja testowa</h1>
            <span
              className='underline-text '
              onClick={() => setShowOptions(true)}
            >
              Zmień pakiet
            </span>
          </div>
          <span className='time-left'>Pakiet ważny do 10.10.2020(14 dni) autoodnawialny</span>
          <div className='option-details-container'>
            <h2 className='details-header'>Szczegóły Twojego pakietu</h2>
            <span className='option-detail'>Ilość urządzeń:1</span>
            <span className='option-detail'>Wiadomości: 20/dzień</span>
            <span className='option-detail'>Archiwum wiadomości: 1 dzień</span>
          </div>
          <div className='option-payments-container'>
            <h1 className='options-header'>Płatności</h1>
            {savedPaymentMethods.length > 0 ? (
              savedPaymentMethods.map((method, index) => {
                return (
                  <SavedMethod
                    method={method}
                    key={index}
                    index={index}
                    deleteMethod={handleDelete}
                  />
                );
              })
            ) : (
              <GreenButton
                noMargin
                handleClick={() => setShowPayment(true)}
              >
                Dodaj metodę płatności / zapłać
              </GreenButton>
            )}
          </div>
          <div className='transaction-table'>
            <h1 className='options-header'>Transakcje</h1>
            {invoices?.length > 0 ? (
              <table>
                <thead>
                  {invoices.map((invoice, index) => {
                    return (
                      <Row
                        key={index}
                        invoice={invoice}
                        index={index}
                      />
                    );
                  })}
                </thead>
              </table>
            ) : (
              <p className='no-invoices'>Nie masz żadnych transakcji.</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MyOption;
