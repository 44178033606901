import authHeader from '../helpers/auth-header';
import handleResponse from '../helpers/handle-response';
import config from '../config';

const getContactList = async ({ page, page_size, name, phone_number }) => {
  const auth = authHeader();

  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
  };

  return fetch(
    `${config.apiUrl}api-front/v1/contact/?page=${page}${page_size ? `&page_size=${page_size}` : ''}${name ? `&name=${name}` : ''}${
      phone_number ? `&phone_number=${phone_number}` : ''
    }`,
    requestOptions,
  )
    .then((r) => handleResponse(r, requestOptions))
    .then((r) => {
      return r.json();
    })
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};
const importContacts = async (body) => {
  const auth = authHeader();

  const requestOptions = {
    method: 'POST',
    headers: { Authorization: auth },
    body: body,
  };

  return fetch(`${config.apiUrl}api-front/v1/contact/import-contacts/`, requestOptions)
    .then((r) => handleResponse(r, requestOptions))
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};
const createContact = async ({ first_name, last_name, description, phone_number }) => {
  const auth = authHeader();

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
    body: JSON.stringify({ first_name, last_name, description, phone_number, is_active: true }),
  };

  return await fetch(`${config.apiUrl}api-front/v1/contact/`, requestOptions)
    .then((r) => handleResponse(r, requestOptions))
    .then((r) => {
      return r.json();
    })
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};
const updateContact = async ({ first_name, last_name, description, phone_number, id }) => {
  const auth = authHeader();

  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
    body: JSON.stringify({ first_name, last_name, description, phone_number, is_active: true }),
  };

  return fetch(`${config.apiUrl}api-front/v1/contact/${id}/`, requestOptions)
    .then((r) => handleResponse(r, requestOptions))
    .then((r) => {
      return r.json();
    })
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};
const deleteContact = async (id) => {
  const auth = authHeader();

  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
  };

  return fetch(`${config.apiUrl}api-front/v1/contact/${id}/`, requestOptions)
    .then((r) => handleResponse(r, requestOptions))
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};
export default { createContact, getContactList, importContacts, updateContact, deleteContact };
