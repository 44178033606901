import React from 'react';

const CloseBackToMainPageIcon = ({ black }) => {
  return (
    <a
      className={black ? 'back-to-main-page-icon-black' : 'back-to-main-page-icon'}
      href='https://www.mesango.com'
    ></a>
  );
};

export default CloseBackToMainPageIcon;
