import React from 'react';
import RoutesPaths from './RoutesPaths';
import Home from './components/pages/Home';
import Login from './components/pages/Login';
import Register from './components/pages/Register';
import Messages from './components/pages/Messages';
import Settings from './components/pages/Settings';
import Contact from './components/pages/Contacts';
import Shop from './components/pages/Shop';
import { PaymentSummary } from './components/pages/PaymentSummary';
import MessagesQueue from './components/pages/MessagesQueue';
import PasswordReset from './components/pages/PasswordReset';
import Import from './components/pages/Import';
import Reset from './components/pages/Reset';
import Help from './components/pages/Help';
import Profile from './components/pages/Profile';

const routerList = [
  {
    path: RoutesPaths.Home.path,
    component: <Home />,
  },
  {
    path: RoutesPaths.Login.path,
    component: <Login />,
  },
  {
    path: RoutesPaths.Register.path,
    component: <Register />,
  },
  {
    path: RoutesPaths.Reset.path,
    component: <Reset />,
  },
  {
    path: RoutesPaths.Messages.path,
    component: <Messages />,
  },
  {
    path: RoutesPaths.Import.path,
    component: <Import />,
  },
  {
    path: RoutesPaths.Settings.path,
    component: <Settings />,
  },
  {
    path: RoutesPaths.Contact.path,
    component: <Contact />,
  },
  // robocze:
  {
    path: RoutesPaths.Shop.path,
    component: <Shop />,
  },
  {
    path: RoutesPaths.Profile.path,
    component: <Profile />,
  },
  {
    path: RoutesPaths.PaymentSummary.path,
    component: <PaymentSummary />,
  },
  {
    path: RoutesPaths.MessagesQueue.path,
    component: <MessagesQueue />,
  },
  {
    path: RoutesPaths.PasswordReset.path,
    component: <PasswordReset />,
  },
  {
    path: RoutesPaths.Help.path,
    component: <Help />,
  },
];

export default routerList;
