import config from '../config';
import handleResponse from '../helpers/handle-response';
import authHeader from '../helpers/auth-header';
import STATUS from './STATUS';

const register = async (form, confirmPrivacy) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: form.email,
      captcha: form.captcha,
      password: form.password,
      is_rules_accepted: confirmPrivacy,
    }),
  };
  return await fetch(`${config.apiUrl}api-front/v1/user/`, requestOptions)
    .then((r) => handleResponse(r))
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};

const getCurrentUser = () => {
  const user = JSON.parse(localStorage.getItem('currentUser'));

  if (user && Object.keys(user).length === 0) {
    return false;
  }

  return user;
};

const logout = (redirect, history) => {
  localStorage.removeItem('currentUser');

  if (redirect) {
    if (history) {
      history('/login');
    } else {
      window.location.href = '/login';
    }
  }
};

const refreshToken = (oldUrl, oldRequestOptions) => {
  const user = getCurrentUser();
  if (!user) {
    logout(true);
    return;
  }

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ refresh: user.refresh }),
  };

  return fetch(`${config.apiUrl}api-front/v1/auth/token/refresh/`, requestOptions)
    .then((r) => {
      if (STATUS.SUCCESS.indexOf(r.status) === -1) {
        logout(true);
      }
      return r.json();
    })
    .then((user) => {
      const accessToken = user;
      const tokens = { ...getCurrentUser(), ...accessToken };
      if (user.access) {
        localStorage.setItem('currentUser', JSON.stringify(tokens));
        if (oldRequestOptions.headers && oldRequestOptions.headers.Authorization) {
          // eslint-disable-next-line no-param-reassign
          oldRequestOptions.headers.Authorization = authHeader();
        }

        return fetch(oldUrl, oldRequestOptions)
          .then()
          .then((r) => handleResponse(r, oldRequestOptions));
      }

      return user;
    })
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};

const loginFetch = (body, token) => {
  body.captcha = token;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };

  return fetch(`${config.apiUrl}api-front/v1/auth/token/`, requestOptions)
    .then((r) => {
      if (r.status === 200) {
        return r.json();
      }
      if (r.status === 400 || r.status === 401) {
        return r.json();
      }
      return r;
    })
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};
const loginAfterRegistration = async (body) => {
  if (body?.refresh) {
    localStorage.setItem('currentUser', JSON.stringify(body));
  }
  return body;
};
const login = async (body, token) => {
  const user = await loginFetch(body, token);
  if (user?.refresh) {
    localStorage.setItem('currentUser', JSON.stringify(user));
  }
  if (user?.refresh || user?.detail) {
    return user;
  }
  return user;
};

const activateAccount = async (pin) => {
  const auth = authHeader();
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
    body: JSON.stringify({ activation_code: pin }),
  };

  return fetch(`${config.apiUrl}api-front/v1/user-activation/`, requestOptions)
    .then((r) => handleResponse(r, requestOptions))
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};
const resendCode = () => {
  const auth = authHeader();
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
  };

  return fetch(`${config.apiUrl}api-front/v1/user/send-activation-code/`, requestOptions)
    .then((r) => handleResponse(r, requestOptions))
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};
const resetPassword = async (form) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: form.email,
      captcha: form.captcha,
    }),
  };
  return await fetch(`${config.apiUrl}api-front/v1/user/reset-password/`, requestOptions)
    .then((r) => handleResponse(r, requestOptions))
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};
export const deleteAccount = async (uuid) => {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  };
  return await fetch(`${config.apiUrl}api-front/v1/user/${uuid}/`, requestOptions)
    .then((r) => handleResponse(r, requestOptions))
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};
export default {
  register,
  refreshToken,
  logout,
  login,
  getCurrentUser,
  loginAfterRegistration,
  activateAccount,
  resendCode,
  resetPassword,
};
