import React from 'react';
import { CssTextField } from '../../../scss/settings/StyledMUIComponents';

const TypePassword = () => {
  return (
    <div className='wrapper'>
      <h1 className='title'>Nadaj nowe hasło</h1>
      <p className='under-title'>
        Pamiętaj, że hasło powinno składać się z min. 8 znaków i zawierać <br /> przynakmniej jedną WIELKĄ literę oraz cyfrę.
      </p>
      <CssTextField
        id='outlined-required'
        label='Nowe hasło'
        sx={{ width: 250, marginBottom: '30px' }}
        // value={form.email}
        name='email'
        // onChange={(e) => handleChange(e)}
        // hasValue={form.email?.length > 0}
      />
      <CssTextField
        id='outlined-required'
        label='Powtórz nowe hasło'
        sx={{ width: 250 }}
        // value={form.email}
        name='email'
        // onChange={(e) => handleChange(e)}
        // hasValue={form.email?.length > 0}
      />
      <button
        style={{ padding: '19px 37px' }}
        className='btn'
      >
        Potwierdź
      </button>
    </div>
  );
};

export default TypePassword;
