import React, { useEffect, useState } from 'react';
import { NOTIFICATION_ENUM } from '../../../../helpers/ENUMS';
import usePopup from '../../../../helpers/usePopup';
import messagesService from '../../../../services/messages.service';
import useNotification from '../../../../helpers/notifications/notificationGenerator';
import ContactAutocomplete from './elements/Autocomplete';
import GreenButton from '../../../Global/GreenButton';
/* eslint-disable no-unused-vars */
const SingleMessage = () => {
  const [selectedValues, setSelectedValues] = useState([]);
  const { popUpState } = usePopup(); //tutaj trzymana jest data z kalendarza
  const [validateError, setValidateError] = useState({ message: false, recipients: false, date: false });
  const { generateNotification } = useNotification();
  const [text, setText] = useState('');
  const characterCount = text.length; // Obliczanie liczby znaków
  useEffect(() => {
    if (popUpState) {
      //jesli jest data to usun blad validacji daty
      setValidateError({ ...validateError, date: false });
    }
  }, [popUpState]);
  const validate = () => {
    let isOk = true;
    let errors = { validateError };
    if (!text) {
      errors = { ...errors, message: true };
      isOk = false;
    }
    setValidateError({ ...errors });
    return isOk;
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    let isOK = validate();
    if (isOK) {
      messagesService
        .sendMessage({
          msg: text,
          recipients_phones: selectedValues.map((user) => user.name),
        })
        .then((r) => {
          if (r.results) {
            generateNotification({
              content: `Wiadomość trafiła do kolejki wysyłania`,
              type: NOTIFICATION_ENUM.SUCCESS,
              time: 4000,
            });
            setSelectedValues([]);
            setText('');
          }
        });
    } else {
      generateNotification({
        content: `Uzupełnij wymagane pola`,
        type: NOTIFICATION_ENUM.ERROR,
        time: 4000,
      });
    }
  };

  // Funkcja obsługująca zmianę tekstu
  const handleChange = (event) => {
    setText(event.target.value);
  };

  return (
    <div className='message-module'>
      <h1 className='message-receiver'>Odbiorca:</h1>
      <ContactAutocomplete
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
      />
      <div className='textarea-wrapper'>
        <textarea
          value={text}
          onChange={handleChange}
          rows='7'
          maxLength={200}
          className='message-content'
          placeholder='Treść wiadomości'
        />
        <span className='characters-remaining'>{characterCount}/200</span>
      </div>
      <GreenButton handleClick={handleSendMessage}>Wyślij wiadomość</GreenButton>
    </div>
  );
};

export default SingleMessage;
