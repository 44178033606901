import React from 'react';
import usePopup from '../../../../helpers/usePopup';
// import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as CloseIcon } from '../../../../assets/close.svg';
import { ReactComponent as QR } from '../../../../assets/qrcode.svg';
const PinPopup = ({ pin }) => {
  const { closePopup, clearIntervalManually } = usePopup();

  const handleClose = (e) => {
    e.preventDefault();
    closePopup();
    clearIntervalManually();
  };
  function podzielPin(pin) {
    let pinString = pin.toString();

    let pierwszaPolowa = pinString.substring(0, 3);
    let drugaPolowa = pinString.substring(3, 6);

    return pierwszaPolowa + ' ' + drugaPolowa;
  }
  return (
    <div className='pin-popup'>
      <div className='close-button'>
        <CloseIcon
          style={{ cursor: 'pointer' }}
          onClick={(e) => handleClose(e)}
        />
      </div>
      <span className='popup-title'>Aktywacja urządzenia</span>
      <span className='popup-subtitle'>Podaj PIN w apce mobilnej</span>
      <div className='pin-container'>
        <span className='pin'>{pin ? podzielPin(pin) : null}</span>
      </div>
      <p className='pin-info'>(Kod PIN ważny jest przez 5 min)</p>
      <div className='line' />
      <span className='qr-title'>Nie masz aplikacji mobilnej?</span>
      <div className='qr-container'>
        <QR />
        <p className='qr-info'>Zeskanuj kod QR, aby bezpiecznie pobrać aplikację Mesango (Android), lub przejdź Google Play.</p>
      </div>
    </div>
  );
};

export default PinPopup;
