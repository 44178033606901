import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import PinPopup from '../../../Global/popup/variants/PinPopup';
import usePopup from '../../../../helpers/usePopup';
import deviceService from '../../../../services/device.service';
import GreenButton from '../../../Global/GreenButton';
import { NOTIFICATION_ENUM } from '../../../../helpers/ENUMS';
import useNotification from '../../../../helpers/notifications/notificationGenerator';

const SingleDeviceSlot = ({ slotNumber, deviceId, active, checkDeviceStatus, last_24h_stats, last_7d_stats, last_30d_stats }) => {
  const { initPopup } = usePopup();
  const { generateNotification } = useNotification();
  const [showDelete, setShowDelete] = useState(false);
  const generatePin = (e) => {
    e.preventDefault();
    deviceService.generatePin({ deviceId: deviceId }).then((r) => {
      console.log(r);
      if (r.pin) {
        initPopup(<PinPopup pin={r.pin} />);
        checkDeviceStatus(deviceId);
      }
    });
  };
  const handleDeactivateDevice = () => {
    deviceService.deactivateDevice(deviceId).then((r) => {
      if (r.ok) {
        generateNotification({
          content: `Urządzenie zostałe dezaktywowane`,
          type: NOTIFICATION_ENUM.SUCCESS,
          time: 4000,
        });
        location.reload();
      } else {
        generateNotification({
          content: `Coś poszło nie tak`,
          type: NOTIFICATION_ENUM.ERROR,
          time: 4000,
        });
      }
    });
  };
  useEffect(() => {
    // Funkcja sprawdzająca czy kliknięcie było poza komponentem
    const handleClickOutside = (event) => {
      // Sprawdź, czy kliknięcie było poza elementem z klasą 'device-delete-dots'
      if (!event.target.classList.contains('device-delete-dots')) {
        if (!event.target.classList.contains('device-delete')) {
          setShowDelete(false);
        }
      }
    };

    // Dodaj nasłuchiwanie kliknięć na całym dokumencie
    document.addEventListener('mousedown', handleClickOutside);

    // Usuń nasłuchiwanie, gdy komponent jest demontowany
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  // Odpytywać listę urządzeń co 5 sekund i sprawdzać czy jest nowe urządzenie podłączone
  return (
    <li className='device'>
      <Grid
        container
        style={{ height: '100%' }}
      >
        <Grid
          item
          xs={12}
          sm={3}
          className='grid-item'
        >
          <span className='device-name'>{slotNumber}. Urządzenie</span>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          className='grid-item'
        >
          {!active ? <span className='inactive'>Nieaktywne</span> : <span className='active'>Aktywne</span>}
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          className='grid-item'
        >
          <div className='device-statistics'>
            <span className='device-text'>Ostatnie 24h:</span>
            <span className='device-text'>Ostatni tydzień:</span>
            <span className='device-text'>Ostatni miesiąc:</span>
          </div>
          <div className='device-statistics-numbers'>
            <span className='device-numbers'>{last_24h_stats}</span>
            <span className='device-numbers'>{last_7d_stats}</span>
            <span className='device-numbers'>{last_30d_stats}</span>
          </div>
        </Grid>
        <Grid
          item
          sm={2}
          className='grid-item'
          style={{
            alignItems: 'center',
            height: '90px',
            justifyContent: 'flex-end',
            paddingRight: '20px',
            flexDirection: 'row',
          }}
        >
          {active ? (
            showDelete ? (
              <>
                <button
                  className='device-delete'
                  onClick={handleDeactivateDevice}
                >
                  Dezaktywuj
                </button>
                <span
                  className='device-delete-dots'
                  onClick={() => setShowDelete((prevState) => !prevState)}
                >
                  ...
                </span>
              </>
            ) : (
              <span
                className='device-delete-dots'
                onClick={() => setShowDelete((prevState) => !prevState)}
              >
                ...
              </span>
            )
          ) : (
            <GreenButton
              noMargin
              handleClick={generatePin}
            >
              Aktywuj
            </GreenButton>
          )}
        </Grid>
      </Grid>
    </li>
  );
};

export default SingleDeviceSlot;
