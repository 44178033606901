import React from 'react';
import RoutesPaths from '../../../../RoutesPaths';

const TitleUrlList = [
  {
    url: RoutesPaths.Home.path,
    component: <p className='title'>Pulpit</p>,
  },
  {
    url: RoutesPaths.Messages.path,
    component: <p className='title'>Wiadomości</p>,
  },
  {
    url: RoutesPaths.Import.path,
    component: <p className='title'>Import wiadomości z pliku</p>,
  },
  {
    url: RoutesPaths.Contact.path,
    component: <p className='title'>Kontakty</p>,
  },
  {
    url: RoutesPaths.Settings.path,
    component: <p className='title'>Integracje</p>,
  },
  {
    url: RoutesPaths.Shop.path,
    component: <p className='title'>Taryfy</p>,
  },
  {
    url: RoutesPaths.PaymentSummary.path,
    component: <p className='title'>Dokonaj zakupu</p>,
  },
  {
    url: RoutesPaths.MessagesQueue.path,
    component: <p className='title'>Kolejka wiadomości</p>,
  },
  {
    url: RoutesPaths.Help.path,
    component: <p className='title'>Pomoc</p>,
  },
  {
    url: RoutesPaths.Profile.path,
    component: <p className='title'>Moje konto - Profil</p>,
  },
];

export default TitleUrlList;
