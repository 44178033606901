import React from 'react';
import { ReactComponent as Visa } from '../../../assets/visa.svg';
const SaveMethod = ({ method, deleteMethod, index }) => {
  return (
    <div className='method-container'>
      <div className='method-header'>
        <span className='method-name'>{method.type === 'Visa' && 'Karta płatnicza'}</span>
        <Visa />
      </div>
      <span className={'method-number'}>{method.number}</span>
      <div className={'method-footer'}>
        <span className={'method-expire'}>{method.expire}</span>
        <div className={'method-buttons'}>
          <span
            className='underline-text'
            onClick={() => deleteMethod(index)}
          >
            Usuń metodę płatności
          </span>
          <span className='underline-text'>Zmień metodę płatności</span>
        </div>
      </div>
    </div>
  );
};

export default SaveMethod;
