import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import dotsIMG from '../../../../../assets/images/table/Group .svg';
import useNotification from '../../../../../helpers/notifications/notificationGenerator';
import { NOTIFICATION_ENUM } from '../../../../../helpers/ENUMS';
import usePopup from '../../../../../helpers/usePopup';
import EditContactPopUp from '../../../../Global/popup/variants/EditContactPopUp';
import contactService from '../../../../../services/contact.service';
import SendMessagePopup from '../../../../Global/popup/variants/SendMessagePopup';

const Popup = ({ user, fetchContacts }) => {
  const { generateNotification } = useNotification();
  const { initPopup } = usePopup();

  const handleSendMessage = () => {
    initPopup(<SendMessagePopup user={user} />);
  };
  const handleDeleteContact = () => {
    contactService.deleteContact(user.id).then((r) => {
      if (r.ok) {
        generateNotification({ type: NOTIFICATION_ENUM.SUCCESS, time: 1500, content: 'Usunięto kontakt' });
        fetchContacts();
      }
    });
  };

  const handleEditMessage = () => {
    initPopup(
      <EditContactPopUp
        user={user}
        fetchContacts={fetchContacts}
      />,
    );
  };

  return (
    <PopupState
      variant='popover'
      popupId='demo-popup-popover'
    >
      {(popupState) => (
        <div>
          <Button
            // variant='contained'
            {...bindTrigger(popupState)}
          >
            <img
              src={dotsIMG}
              alt='kropki'
            />
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
          >
            <Typography
              onClick={() => handleSendMessage()}
              className='contact-popup'
              sx={{
                p: 2,
                '&:hover': {
                  backgroundColor: 'white', // Ustawienie tła na białe
                  color: '#939393', // Zmiana koloru tekstu
                },
              }}
            >
              Wyślij wiadomość
            </Typography>
            <Typography
              className='contact-popup'
              onClick={() => handleEditMessage()}
              sx={{
                p: 2,
                '&:hover': {
                  backgroundColor: 'white', // Ustawienie tła na białe
                  color: '#939393', // Zmiana koloru tekstu
                },
              }}
            >
              Edytuj
            </Typography>
            <Typography
              className='contact-popup'
              onClick={() => handleDeleteContact()}
              sx={{
                p: 2,
                '&:hover': {
                  backgroundColor: 'white', // Ustawienie tła na białe
                  color: '#939393', // Zmiana koloru tekstu
                },
              }}
            >
              Usuń
            </Typography>
          </Popover>
        </div>
      )}
    </PopupState>
  );
};

export default Popup;
