import React, { useState } from 'react';
// import InfoBox from './elements/InfoBox';
import PinPopup from '../../../Global/popup/variants/PinPopup';
import usePopup from '../../../../helpers/usePopup';
import deviceService from '../../../../services/device.service';
import { ReactComponent as EditIcon } from '../../../../assets/edit.svg';
import { ReactComponent as DotsIcon } from '../../../../assets/dots.svg';
import GreenButton from '../../../Global/GreenButton';
import { NOTIFICATION_ENUM } from '../../../../helpers/ENUMS';
import useNotification from '../../../../helpers/notifications/notificationGenerator';

const MobileSingleDeviceSlot = ({ slotNumber, deviceId, active, checkDeviceStatus, last_24h_stats, last_7d_stats, last_30d_stats }) => {
  const { initPopup } = usePopup();
  const { generateNotification } = useNotification();
  const [showDelete, setShowDelete] = useState(false);
  const generatePin = (e) => {
    e.preventDefault();
    deviceService.generatePin({ deviceId: deviceId }).then((r) => {
      console.log(r);
      if (r.pin) {
        initPopup(<PinPopup pin={r.pin} />);
        checkDeviceStatus(deviceId);
      }
    });
  };
  const handleDeactivateDevice = () => {
    deviceService.deactivateDevice(deviceId).then((r) => {
      if (r.ok) {
        generateNotification({
          content: `Urządzenie zostałe dezaktywowane`,
          type: NOTIFICATION_ENUM.SUCCESS,
          time: 4000,
        });
        location.reload();
      } else {
        generateNotification({
          content: `Coś poszło nie tak`,
          type: NOTIFICATION_ENUM.ERROR,
          time: 4000,
        });
      }
    });
  };
  return (
    <li className='mobile-device'>
      <div className='device-header'>
        <div className='header-actions'>
          <span className='device-name'>Urzadzenie {slotNumber}</span>
          <EditIcon />
        </div>
        <div className='header-more-actions'>
          {active ? (
            <>
              <DotsIcon onClick={() => setShowDelete((prevState) => !prevState)} />
              {showDelete ? (
                <>
                  <button
                    className='device-delete'
                    onClick={handleDeactivateDevice}
                  >
                    Dezaktywuj
                  </button>
                </>
              ) : null}
            </>
          ) : (
            <GreenButton handleClick={generatePin}>Aktywuj</GreenButton>
          )}
        </div>
      </div>
      <div className='device-activation'>{!active ? <span className='inactive'>Nieaktywne</span> : <span className='active'>Aktywne</span>}</div>
      <div className='device-statistics'>
        <span className='device-text'>
          Ostatnie 24h: <span className='device-numbers'>{last_24h_stats}</span>
        </span>
        <span className='device-text'>
          Ostatni tydzień: <span className='device-numbers'>{last_7d_stats}</span>
        </span>
        <span className='device-text'>
          Ostatni miesiąc: <span className='device-numbers'>{last_30d_stats}</span>
        </span>
      </div>
    </li>
  );
};

export default MobileSingleDeviceSlot;
