/* eslint-disable no-undef */
export default {
  appTitle: process.env.REACT_APP_TITLE,
  apiUrl: process.env.REACT_APP_API_URL,
  publicUrl: process.env.REACT_APP_PUBLIC_URL,
  captchaSitekey: process.env.REACT_APP_CAPTCHA_SITEKEY,
  tariffPayment: process.env.REACT_APP_P24_PAYMENT_URL,
  apiDocsUrl: process.env.REACT_APP_API_DOCS_URL,
  rulesUrl: process.env.REACT_APP_RULES_URL,
  privacyPolicyUrl: process.env.REACT_APP_PRIVACY_POLICY_URL,
};
