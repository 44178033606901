import React from 'react';

const GreenButton = ({ children, handleClick, noMargin, submit, fullWidth, disabled }) => {
  return submit ? (
    <button
      className={`global-btn ${!noMargin ? 'no-margin' : ''} ${fullWidth ? 'full-width' : ''}`}
      type='submit'
    >
      {children}
    </button>
  ) : (
    <button
      onClick={(e) => handleClick(e)}
      className={`global-btn ${disabled && 'disabled'} ${noMargin ? 'no-margin' : ''} ${fullWidth ? 'full-width' : ''}`}
    >
      {children}
    </button>
  );
};

export default GreenButton;
