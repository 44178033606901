import React from 'react';
import { CssTextField } from '../../../scss/settings/StyledMUIComponents';

const TypeEmail = () => {
  return (
    <div className='wrapper'>
      <h1 className='title'>Reset hasła</h1>
      <p className='under-title'>
        Wpisz poniżej adres email połączony z Twoim kontem, a następnie <br /> sprawdź skrzynkę odbiorczą
      </p>
      <CssTextField
        id='outlined-required'
        label='E-mail'
        sx={{ width: 250 }}
        // value={form.email}
        name='email'
        // onChange={(e) => handleChange(e)}
        // hasValue={form.email?.length > 0}
      />
      <button className='btn'>Resetuj hasło</button>
    </div>
  );
};

export default TypeEmail;
