import { useState } from 'react';
import { createContext } from 'use-context-selector';
import { MESSAGES_TYPE_ENUM } from './helpers/ENUMS';

export const Context = createContext();

export const StateProvider = ({ children }) => {
  const [messagesPage, setMessagesPage] = useState({ currentType: MESSAGES_TYPE_ENUM.SINGLE, userIdToSend: [] });
  const [notification, setNotification] = useState({ type: '', content: '', display: false });
  const [popup, setPopup] = useState({ display: false, component: null, state: null });
  const [contactPageSearchData, setContactPageSearchData] = useState('');
  const [recipients, setRecipientIds] = useState([]);
  const [organizationId, setOrganizationId] = useState();
  const [isAuth, setIsAuth] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  //helpers
  //dodaje lub usuwa usera  z listy gdy się na niej znajduje
  const handleUserRecipient = (user) => {
    const userExistsInRecipients = recipients.find((recipient) => recipient.id == user.id);
    if (userExistsInRecipients) {
      setRecipientIds(recipients.filter((existingUser) => user.id != existingUser.id));
    } else {
      setRecipientIds([...recipients, user]);
    }
  };

  return (
    <Context.Provider
      value={{
        popup,
        setPopup,
        notification,
        setNotification,
        messagesPage,
        setMessagesPage,
        contactPageSearchData,
        setContactPageSearchData,
        recipients,
        setRecipientIds,
        handleUserRecipient,
        setIntervalId,
        intervalId,
        organizationId,
        setOrganizationId,
        isAuth,
        setIsAuth,
      }}
    >
      {children}
    </Context.Provider>
  );
};
