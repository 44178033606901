import React, { useEffect, useState } from 'react';
import CloseBackToMainPageIcon from '../Global/CloseBackToMainPageIcon';
import { useSearchParams } from 'react-router-dom';
import TypePassword from '../elements/PasswordReset/TypePassword';
import TypeEmail from '../elements/PasswordReset/TypeEmail';

const STAGE_ENUM = {
  EMAIL: 'email',
  NEW_PASSWORD: 'new_password',
};

const PasswordReset = () => {
  let [searchParams] = useSearchParams();
  const [stage, setStage] = useState();

  useEffect(() => {
    const urlStage = searchParams.get('stage');
    setStage(urlStage);
  }, [searchParams]);

  return (
    <div className='page-reset-password'>
      <CloseBackToMainPageIcon black />
      {stage == STAGE_ENUM.NEW_PASSWORD && <TypePassword />}
      {stage == STAGE_ENUM.EMAIL && <TypeEmail />}
    </div>
  );
};

export default PasswordReset;
