import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { CssTextField } from '../../scss/settings/StyledMUIComponents';
import authenticationService from '../../services/authentication.service';
import RoutesPaths from '../../RoutesPaths';
import CloseBackToMainPageIcon from '../Global/CloseBackToMainPageIcon';
import GreenButton from '../Global/GreenButton';
import Checkbox from '../Global/Checkbox';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { ReactComponent as Logo } from '../../assets/images/logo_mesango.svg';
import config from '../../config';

const Register = () => {
  const [form, setForm] = useState({ email: '', password: '', captcha: '' });
  const captchaRef = useRef(null);
  const [error, setError] = useState({});
  const [token, setToken] = useState('');
  const [confirmPrivacy, setConfirmPrivacy] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [captchaKey, setCaptchaKey] = useState(0);
  const navigate = useNavigate();
  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  const validatePassword = (password) => {
    const lengthCheck = password.length >= 8;
    const digitCheck = /\d/.test(password); // Sprawdza, czy jest cyfra
    const specialCharCheck = /[!@#$%^&*(),.?":{}|<>]/.test(password); // Sprawdza, czy jest znak specjalny

    return lengthCheck && digitCheck && specialCharCheck;
  };
  const handleChange = (e) => {
    if (token.length > 0) {
      setCaptchaKey((prevKey) => prevKey + 1);
      setToken('');
    }
    setError({});
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  useEffect(() => {
    document.title = `${config.appTitle} | Rejestracja`;
  }, []);
  const validateLoginData = () => {
    let error = false;
    if (!form.email) {
      setError((prevState) => {
        return { ...prevState, email: 'Uzupełnij pole' };
      });
      error = true;
    } else {
      if (!validateEmail(form.email)) {
        setError((prevState) => {
          return { ...prevState, email: 'Niepoprawny adres email' };
        });
        error = true;
      }
    }
    if (!form.password) {
      setError((prevState) => {
        return { ...prevState, password: 'Uzupełnij pole' };
      });
      error = true;
    } else {
      if (!validatePassword(form.password)) {
        setError((prevState) => {
          return {
            ...prevState,
            password: 'Hasło powinno składać się z min. 8 znaków i zawierać przynajmniej jeden znak specjalny oraz cyfrę.',
          };
        });
        error = true;
      }
    }
    if (!confirmPrivacy) {
      setError((prevState) => {
        return { ...prevState, privacy: 'Zaakceptuj regulamin' };
      });
      error = true;
    }
    if (form.email && form.password && confirmPrivacy && token.length <= 0) {
      setError((prevState) => {
        return { ...prevState, captcha: 'Wypełnij Captche' };
      });
      error = true;
    }
    return error;
  };

  const sendRegister = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (validateLoginData()) {
      setIsLoading(false);
      return;
    }
    // form.captcha = token;
    form.captcha = token;
    authenticationService.register(form, confirmPrivacy).then((r) => {
      authenticationService.loginAfterRegistration(r).then((response) => {
        if (response?.access) {
          navigate(RoutesPaths.Home.path);
          setIsLoading(false);
        } else {
          if (response.errors) {
            setError((prevState) => {
              return { ...prevState, detail: r.errors[0].detail };
            });
            setIsLoading(false);
          } else {
            setError((prevState) => {
              return { ...prevState, detail: 'Coś poszło nie tak.' };
            });
            setIsLoading(false);
          }
        }
      });
    });
  };

  return (
    <main className='page-register'>
      <CloseBackToMainPageIcon />
      <section className='left'>
        <CloseBackToMainPageIcon black />
        <div className='logo-container'>
          <Logo style={{ width: 150 }} />
        </div>
        <Box
          component='form'
          sx={{
            width: { xs: '100%', sm: 360 },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <h2 className='main-page-title'>Twoje nowe konto.</h2>
          <div className='inputs-container'>
            <div className='input-container'>
              <CssTextField
                id='outlined-required'
                label='Email'
                fullWidth
                value={form.email}
                name='email'
                onChange={(e) => handleChange(e)}
                hasValue={form.email?.length > 0}
                error={error.email}
              />
              {error?.email && <p className='register-error'>{error?.email ? error.email : ' '}</p>}
            </div>
            <div className='input-container'>
              <CssTextField
                id='outlined-required'
                label='Hasło'
                type='password'
                fullWidth
                value={form.password}
                name='password'
                onChange={(e) => handleChange(e)}
                hasValue={form.password?.length > 0}
                inputProps={{
                  autoComplete: 'new-password',
                }}
                error={error.password}
              />
              {error?.password && <p className='register-error'>{error?.password ? error.password : ' '}</p>}
              {error?.detail && <p className='register-error'>{error?.detail ? error.detail : ' '}</p>}
            </div>
          </div>
          <div className='privacy-policy-wrapper'>
            <Checkbox
              checked={confirmPrivacy}
              error={error.privacy}
              onChange={() => {
                setConfirmPrivacy(!confirmPrivacy);
                setError((prevState) => {
                  return { ...prevState, privacy: '' };
                });
              }}
            />
            <p className='privacy-policy'>
              Zapoznałem/am się i akceptuję{' '}
              <a
                href={`${config.rulesUrl}`}
                target='_blank'
                rel='noreferrer'
              >
                Regulamin
              </a>{' '}
              oraz{' '}
              <a
                href={`${config.privacyPolicyUrl}`}
                target='_blank'
                rel='noreferrer'
              >
                Politykę Prywatności
              </a>
            </p>
          </div>
          {error?.privacy && <p className='register-error'>{error?.privacy ? error.privacy : ' '}</p>}
          <div
            style={
              form.email.length > 0 && form.password.length > 0 && confirmPrivacy
                ? {
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '20px',
                    width: '100%',
                  }
                : { display: 'none' }
            }
          >
            <HCaptcha
              sitekey={config.captchaSitekey}
              ref={captchaRef}
              key={captchaKey}
              onVerify={(token) => {
                setToken(token);
                setError({});
              }}
            />
          </div>
          {error?.captcha && (
            <p
              className='register-error'
              style={{ width: '100%', textAlign: 'center' }}
            >
              {error?.captcha ? error.captcha : ' '}
            </p>
          )}
          <div
            className='login-elements-wrapper-width-captcha'
            style={{ width: '100%' }}
          >
            <div className='login-vertical-elements'>
              <GreenButton handleClick={sendRegister}>{isLoading ? <span className='loader-white'></span> : 'Zaczynamy!'}</GreenButton>
              <p className='has-account'>
                Masz już konto? <a href='/login'>Zaloguj się</a>
              </p>
            </div>
          </div>
        </Box>
      </section>
      <div className='right' />
    </main>
  );
};

export default Register;
