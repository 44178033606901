import React from 'react';
import usePopup from '../../helpers/usePopup';
import SetDeviceTarrif from './popup/variants/SetDeviceTarrif';

const TarrifBox = ({ avaibleSelect, tarrif, isPromote }) => {
  const { initPopup } = usePopup();

  const chooseTariff = (e) => {
    e.preventDefault();
    initPopup(<SetDeviceTarrif tarrif={tarrif} />);
  };

  return (
    <div className={`list-wrapper ${isPromote ? 'promote-list' : ''}`}>
      {isPromote && (
        <div className='promote-title-wrapper'>
          <p>Najlepszy wybór</p>
        </div>
      )}
      <div className='list-title list-title-pricing here'>
        <div className='name'>STARTER</div>
        <p className='price'>0 zł</p>
        <p className='period'>konto na zawsze darmowe</p>
        <div />
      </div>
      <ul className='offer-list here'>
        <div>
          <li>
            <p className='key'>value</p>
            <p className='value'>tytuł</p>
          </li>
        </div>
        <div>
          <li>
            <p className='key'>value</p>
            <p className='value'>tytuł</p>
          </li>
        </div>
        <div>
          <li>
            <p className='key'>value</p>
            <p className='value'>tytuł</p>
          </li>
        </div>
        <div>
          <li>
            <p className='key'>value</p>
            <p className='value'>tytuł</p>
          </li>
        </div>
        <div>
          <li>
            <p className='key'>value</p>
            <p className='value'>tytuł</p>
          </li>
        </div>
        <div>
          <li>
            <p className='key'>value</p>
            <p className='value'>tytuł</p>
          </li>
        </div>
        <div>
          <li>
            <p className='key'>value</p>
            <p className='value'>tytuł</p>
          </li>
        </div>
      </ul>
      <div className='button-wrapper'>
        {avaibleSelect && (
          <button
            onClick={(e) => chooseTariff(e)}
            className={`buy-button ${isPromote ? 'buy-button-promote' : 'buy-button-no-promote'}`}
          >
            Wybieram
          </button>
        )}
      </div>
    </div>
  );
};

export default TarrifBox;
