import React, { useEffect } from 'react';
import PageWrapper from '../wrappers/PageWrapper';
import Header from '../elements/Contacts/Header';
import TableBox from '../elements/Contacts/TableBox/TableBox';
import Pagination from '../elements/Contacts/TableBox/elements/Pagination';
import { Context } from '../../Context';
import { useContextSelector } from 'use-context-selector';
import useFilterLiveHook from '../../helpers/notifications/hooks/useFilterLiveHook';
import CircularProgress from '@mui/material/CircularProgress';

const Contacts = () => {
  const { contactPageSearchData } = useContextSelector(Context, (state) => state);
  const {
    filteredArray,
    isLoading,
    setPhone_number,
    setName,
    page,
    fetchContacts,
    goToNextPage,
    goToPrevPage,
    nextPageAvaible,
    prevPageAvaible,
    pageAmmount,
  } = useFilterLiveHook({ page_size: 20 });

  useEffect(() => {
    if (isNaN(contactPageSearchData)) {
      setName(contactPageSearchData);
      setPhone_number();
    } else {
      setName('');
      setPhone_number(contactPageSearchData);
    }
  }, [contactPageSearchData, filteredArray]);

  return (
    <PageWrapper>
      <div className='contact-page-wrapper'>
        <Header fetchContacts={fetchContacts} />
        {isLoading && <CircularProgress />}
        {!isLoading && (
          <TableBox
            contactList={filteredArray}
            fetchContacts={fetchContacts}
          />
        )}

        {nextPageAvaible && (
          <Pagination
            pageAmmount={pageAmmount}
            page={page}
            nextPageAvaible={nextPageAvaible}
            goToNextPage={goToNextPage}
            goToPrevPage={goToPrevPage}
            prevPageAvaible={prevPageAvaible}
          />
        )}
      </div>
    </PageWrapper>
  );
};

export default Contacts;
