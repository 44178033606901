import authHeader from '../helpers/auth-header';
import handleResponse from '../helpers/handle-response';
import config from '../config';

const getQueueSms = async ({ page, is_served }) => {
  const auth = authHeader();

  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
  };

  return fetch(`${config.apiUrl}api-front/v1/sms/?is_served=${is_served}&page=${page}`, requestOptions)
    .then((r) => handleResponse(r, requestOptions))
    .then((r) => {
      return r.json();
    })
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};
const getQueueSmsById = async ({ page, is_served, id }) => {
  const auth = authHeader();

  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
  };

  return fetch(`${config.apiUrl}api-front/v1/sms/?sms_import=${id}&is_served=${is_served}&page=${page}`, requestOptions)
    .then((r) => handleResponse(r, requestOptions))
    .then((r) => {
      return r.json();
    })
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};
const importMessages = async (file) => {
  const auth = authHeader();

  const requestOptions = {
    method: 'POST',
    headers: { Authorization: auth },
    body: file,
  };
  return await fetch(`${config.apiUrl}api-front/v1/sms-import/`, requestOptions)
    .then((r) => handleResponse(r, requestOptions))
    .then((r) => {
      return r.json();
    })
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};
const fetchAddedFiles = async () => {
  const auth = authHeader();

  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
  };
  return await fetch(`${config.apiUrl}api-front/v1/sms-import/`, requestOptions)
    .then((r) => handleResponse(r, requestOptions))
    .then((r) => {
      return r.json();
    })
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};
const getRecivedSms = async ({ page }) => {
  const auth = authHeader();

  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
  };

  return fetch(`${config.apiUrl}api-front/v1/received-sms/?page=${page}`, requestOptions)
    .then((r) => handleResponse(r, requestOptions))
    .then((r) => {
      return r.json();
    })
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};
const getReceivedSmsById = async ({ page, id }) => {
  const auth = authHeader();

  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
  };

  return fetch(`${config.apiUrl}api-front/v1/received-sms/?page=${page}&sms_import=${id}`, requestOptions)
    .then((r) => handleResponse(r, requestOptions))
    .then((r) => {
      return r.json();
    })
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};

const sendMessage = async ({ msg, recipients_phones }) => {
  const auth = authHeader();

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
    body: JSON.stringify({ recipients_phones, msg }),
  };

  return fetch(`${config.apiUrl}api-front/v1/sms/`, requestOptions)
    .then((r) => handleResponse(r, requestOptions))
    .then((r) => {
      return r.json();
    })
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};
const acceptShipment = async (id) => {
  const auth = authHeader();

  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
    body: JSON.stringify({ status: 'accepted' }),
  };

  return fetch(`${config.apiUrl}api-front/v1/sms-import/${id}/`, requestOptions)
    .then((r) => handleResponse(r, requestOptions))
    .then((r) => {
      return r.json();
    })
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};

export default { getQueueSms, getRecivedSms, sendMessage, importMessages, fetchAddedFiles, acceptShipment, getQueueSmsById, getReceivedSmsById };
