import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { CssTextField } from '../../scss/settings/StyledMUIComponents';
import authenticationService from '../../services/authentication.service';
import RoutesPaths from '../../RoutesPaths';
import CloseBackToMainPageIcon from '../Global/CloseBackToMainPageIcon';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { ReactComponent as Logo } from '../../assets/images/logo_mesango.svg';
import GreenButton from '../Global/GreenButton';
import config from '../../config';

const Login = () => {
  const [form, setForm] = useState({ email: '', password: '', captcha: '' });
  const [error, setError] = useState({});
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [captchaKey, setCaptchaKey] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `${config.appTitle} | Logowanie`;
  });
  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
    setError({});

    if (token.length > 0) {
      setCaptchaKey((prevKey) => prevKey + 1);
      setToken('');
    }
  };

  const validateLoginData = () => {
    let error = false;
    if (!form.email) {
      setError((prevState) => {
        return { ...prevState, email: 'Uzupełnij pole' };
      });
      error = true;
    } else {
      if (!validateEmail(form.email)) {
        setError((prevState) => {
          return { ...prevState, email: 'Niepoprawny adres email' };
        });
        error = true;
      }
    }
    if (!form.password) {
      setError((prevState) => {
        return { ...prevState, password: 'Uzupełnij pole' };
      });
      error = true;
    }
    if (token.length <= 0) {
      setError((prevState) => {
        return { ...prevState, captcha: 'Rozwiąż Captche' };
      });
      error = true;
    }
    return error;
  };

  const sendLogin = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (validateLoginData()) {
      setIsLoading(false);
      return;
    }
    authenticationService
      .login(form, token)
      .then((r) => {
        if (r.errors) {
          if (r.errors[0].code === 'no_active_account') {
            setError({ detail: 'Konto o podanych danych nie istnieje' });
            setIsLoading(false);
          } else {
            setError({ detail: 'Coś posżło nie tak, spróbuj ponownie za chwilę.' });
            setIsLoading(false);
          }
        } else {
          setError({ detail: 'Coś posżło nie tak, spróbuj ponownie za chwilę.' });
          setIsLoading(false);
        }
        if (r.refresh) {
          setIsLoading(false);
          navigate(RoutesPaths.Home.path);
        }
      })
      .catch((r) => {
        setIsLoading(false);
        console.log(r);
      });
  };

  return (
    <main className='page-login'>
      <CloseBackToMainPageIcon />
      <section className='left'>
        <CloseBackToMainPageIcon black />
        <div className='logo-container'>
          <Logo style={{ width: 150 }} />
        </div>
        <Box
          component='form'
          sx={{
            width: { xs: '100%', sm: 360 },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <h2 className='main-page-title'>Witamy ponownie.</h2>
          <div className='input-container'>
            <CssTextField
              id='outlined-required'
              label='E-mail'
              fullWidth
              value={form.email}
              name='email'
              onChange={(e) => handleChange(e)}
              error={error.email || error.detail}
              hasValue={form.email?.length > 0}
              inputProps={{
                autoComplete: 'new-password',
              }}
            />
            {error?.email && <p className='register-error'>{error?.email ? error.email : ' '}</p>}
          </div>
          <div className='input-container'>
            <CssTextField
              id='outlined-required'
              label='Hasło'
              fullWidth
              type='password'
              value={form.password}
              error={error.password || error.detail}
              name='password'
              onChange={(e) => handleChange(e)}
              hasValue={form.password?.length > 0}
            />
            {error?.password && <p className='register-error'>{error?.password ? error.password : ' '}</p>}
            {error?.detail && <p className='register-error'>{error?.detail ? error.detail : ' '}</p>}
          </div>
          <div className={form.email.length > 0 && form.password.length > 0 ? 'captcha-container' : 'captcha-none'}>
            <HCaptcha
              sitekey={config.captchaSitekey}
              key={captchaKey}
              onVerify={(token) => {
                setToken(token);
                setError((prevState) => {
                  return { ...prevState, captcha: ' ' };
                });
              }}
            />
            {error?.captcha && <p className='register-error'>{error?.captcha ? error.captcha : ' '}</p>}
          </div>
          <div className='login-elements-wrapper-with-captcha'>
            <div className='login-vertical-elements'>
              <GreenButton handleClick={sendLogin}>{isLoading ? <span className='loader-white'></span> : 'Zaloguj się'}</GreenButton>
              <p>
                Nie masz jeszcze konta?{' '}
                <a
                  className='create'
                  href='/register'
                >
                  Utwórz
                </a>
              </p>
              <a href='/reset'>Problemy z logowaniem?</a>
            </div>
          </div>
        </Box>
      </section>
      <div className='right' />
    </main>
  );
};

export default Login;
