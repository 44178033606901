import React, { useState } from 'react';
import { useContextSelector } from 'use-context-selector';
import { Context } from '../../Context';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant='filled'
      {...props}
    />
  );
});
const Notification = () => {
  const notification = useContextSelector(Context, (state) => state.notification);

  const [state] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal } = state;

  return (
    <Snackbar
      color='#54D76A'
      anchorOrigin={{ vertical, horizontal }}
      open={notification.display}
      key={vertical + horizontal}
    >
      <Alert
        severity={notification.type}
        sx={{ width: '100%' }}
      >
        {notification.content}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
