export const NOTIFICATION_ENUM = {
  ERROR: 'error',
  SUCCESS: 'success',
  NOTIFICATION: 'info',
};

export const MESSAGES_TYPE_ENUM = {
  BULK: 'Załącz plik csv by dokonać masowej wysyłki',
  SINGLE: 'Wybierz odbiorców do których ma trafić wiadomość',
};

export const SINGLE_MESSAGE_ARRAY_TYPE_ENUM = {
  ALL: 'all',
  SELECTED: 'selected',
  GROUP: 'group',
};

export const MESSAGE_DISPLAY_TYPE = {
  SERVED: 'served',
  NO_SERVED: 'selected',
  RECIVED: 'group',
};

export const SETTING_SECTION_ENUM = {
  PAYMENT_HISTORY: 'payment_history',
  CARD: 'card',
  CHANGE_PASSWORD: 'change_password',
};
