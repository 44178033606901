import React, { useState } from 'react';
import PageWrapper from '../wrappers/PageWrapper';

import Options from '../elements/Shop/Options';
import MyOption from '../elements/Shop/MyOption';
import { useLocation } from 'react-router-dom';

const Shop = () => {
  const [showOptions, setShowOptions] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const changedInvoiceData = searchParams.get('changedInvoiceData');
  return (
    <PageWrapper>
      {showOptions ? (
        <Options setShowOptions={setShowOptions} />
      ) : (
        <MyOption
          changedInvoiceData={changedInvoiceData}
          setShowOptions={setShowOptions}
        />
      )}
    </PageWrapper>
  );
};

export default Shop;
