import React from 'react';
import { Grid } from '@mui/material';
import APIKey from './APIKey';

const KeysList = ({ setRefetch, data, page }) => {
  return (
    <div
      className='key-list-wrapper'
      id='queue-list'
    >
      <Grid
        container
        className='grid-container'
      >
        <Grid
          item
          xs={0.3}
          md={0.3}
        ></Grid>
        <Grid
          item
          xs={2}
          md={2}
        >
          <span className='table-h'>Nazwa klucza</span>
        </Grid>
        <Grid
          item
          xs={4}
          md={2}
        >
          <span className='table-h'>Data wygenerowania</span>
        </Grid>
        <Grid
          item
          xs={5.4}
          md={7.4}
        >
          <span className='table-h'>Data ważności</span>
        </Grid>
        <Grid
          item
          xs={0.3}
          md={0.3}
        ></Grid>
      </Grid>
      {data.length === 0 && (
        <div className='no-messages'>
          <span>Brak dodanych kluczy</span>
        </div>
      )}
      <ul className='messages-list'>
        {data.map((element, index) => {
          if (!element.revoked) {
            return (
              <li
                className='message-item'
                key={index}
              >
                <APIKey
                  uuid={element.uuid}
                  setRefetch={setRefetch}
                  id={index + 1 + (page - 1) * 10}
                  key_name={element.name}
                  created_at={element.created}
                  due_to={element.created}
                />
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default KeysList;
