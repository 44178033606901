import authHeader from '../helpers/auth-header';
import handleResponse from '../helpers/handle-response';
import config from '../config';
/* eslint-disable no-unused-vars */
const getAPIKeys = async (page) => {
  const auth = authHeader();

  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
  };

  return fetch(`${config.apiUrl}api-front/v1/api-key/?page=${page}`, requestOptions)
    .then((r) => handleResponse(r, requestOptions))
    .then((r) => {
      return r.json();
    })
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};
const createKey = async (name) => {
  const auth = authHeader();

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
    body: JSON.stringify({ name }),
  };

  return fetch(`${config.apiUrl}api-front/v1/api-key/`, requestOptions)
    .then((r) => handleResponse(r, requestOptions))
    .then((r) => {
      return r.json();
    })
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};
const deleteKey = async (uuid) => {
  const auth = authHeader();
  const requestOptions = {
    method: 'DELETE',
    headers: { Authorization: auth },
  };
  return fetch(`${config.apiUrl}api-front/v1/api-key/${uuid}/`, requestOptions)
    .then((r) => handleResponse(r, requestOptions))
    .catch((error) => {
      return { error: true, errorContent: error };
    });
};
export default { getAPIKeys, createKey, deleteKey };
