import { NOTIFICATION_ENUM } from '../ENUMS';

const validateAddContact = ({ first_name, last_name, description, phone_number, is_active }) => {
  const object = { first_name, last_name, description, phone_number, is_active };
  if (Object.keys(object).every((k) => !object[k])) {
    console.log('Podaj przynajmniej jedną wartość');
    return {
      type: NOTIFICATION_ENUM.ERROR,
      field: ['phone_number', 'first_name', 'last_name'],
      content: 'Podana musi być co najmniej jedna wartość',
    };
  }
  if (phone_number) {
    if (isNaN(phone_number)) {
      return { type: NOTIFICATION_ENUM.ERROR, field: ['phone_number'], content: 'numer telefonu musi zawierać cyfry' };
    }
  }
  return { content: 'Pomyślnie dodano kontakt', type: NOTIFICATION_ENUM.SUCCESS, time: 4000 };
};

export default validateAddContact;

export const validateAddContactResponse = (response) => {
  if (!response.errors) {
    return { content: 'Pomyślnie dodano kontakt', type: NOTIFICATION_ENUM.SUCCESS, time: 4000 };
  } else {
    return { content: response.errors[0].detail, type: NOTIFICATION_ENUM.ERROR, time: 4000 };
  }
};
