import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@emotion/react';
import Dialog from '@mui/material/Dialog';
import usePopup from '../../../helpers/usePopup';

const Popup = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { closePopup, popup, clearIntervalManually } = usePopup();

  const handleClose = (e) => {
    e.preventDefault();
    closePopup();
    clearIntervalManually();
  };
  return (
    <Dialog
      fullScreen={fullScreen}
      open={popup.display}
      onClose={handleClose}
      aria-labelledby='responsive-dialog-title'
    >
      {popup.component}
    </Dialog>
  );
};

export default Popup;
