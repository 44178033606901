import React, { useEffect, useState } from 'react';
import PageWrapper from '../wrappers/PageWrapper';
import deviceService from '../../services/device.service';
// import mobileService from '../../services/mobile.service';
import DeviceSlotList from '../elements/Home/DeviceSlotList/DeviceSlotList';
import useNotification from '../../helpers/notifications/notificationGenerator';
import { NOTIFICATION_ENUM } from '../../helpers/ENUMS';
import usePopup from '../../helpers/usePopup';
import PinPopup from '../Global/popup/variants/PinPopup';

const Home = () => {
  const [deviceList, setDeviceList] = useState([]);
  const { initPopup, closePopup, setIntervalId } = usePopup();
  const handleFetchDeviceList = async () => {
    await deviceService.getDeviceList().then((r) => {
      if (r.results) {
        setDeviceList(r.results);
      }
    });
  };
  useEffect(() => {
    handleFetchDeviceList();
  }, []);
  const { generateNotification } = useNotification();

  const checkDeviceStatus = (deviceId) => {
    const id = setInterval(() => {
      deviceService.getDeviceList().then((r) => {
        if (r.results) {
          const foundDevice = r.results.find((device) => device.id === deviceId);
          if (foundDevice && foundDevice.is_active) {
            clearInterval(id);
            closePopup();
            deviceService.getDeviceList().then((r) => {
              if (r.results) {
                setDeviceList(r.results);
              }
            });
            // Możesz tutaj wykonać dodatkowe akcje po znalezieniu aktywnego urządzenia
          }
        }
      });
    }, 2000);
    setIntervalId(id);
  };

  const createDevice = (e) => {
    e.preventDefault();
    let noConntectedDevice = deviceList.find((device) => device.is_active === false);
    if (noConntectedDevice) {
      //jeśli instieje już slot urządzenia do wykorzystania
      deviceService.generatePin({ deviceId: noConntectedDevice.id }).then((r) => {
        if (r.pin) {
          initPopup(<PinPopup pin={r.pin} />);
        }
      });
    } else {
      //jeśli nie ma jeszcze slotu to go tworzy
      console.log('Nie ma jeszcze slotu');
      deviceService.createDevice().then((r) => {
        if (r.non_field_errors) {
          generateNotification({ type: NOTIFICATION_ENUM.ERROR, time: 3500, content: r.non_field_errors[0] });
        } else {
          console.log(r);
          if (r.id) {
            deviceService.generatePin({ deviceId: r.id }).then((r) => {
              if (r.pin) {
                initPopup(<PinPopup pin={r.pin} />);
              }
            });
          }
        }
      });
    }
  };
  return (
    <PageWrapper>
      <h1 className='home-package'>
        <span style={{ marginRight: '30px' }}>Aktywny pakiet: darmowy</span>
        <a
          className='underline-text'
          href='/shop'
        >
          Zarządzaj
        </a>
      </h1>
      <h5 className='home-title'>Twoje urządzenia</h5>
      <DeviceSlotList
        deviceList={deviceList}
        createDevice={createDevice}
        checkDeviceStatus={checkDeviceStatus}
      />
    </PageWrapper>
  );
};

export default Home;
