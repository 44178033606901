import React from 'react';
import PageWrapper from '../wrappers/PageWrapper';
import SingleMessage from '../elements/Messages/SingleMessage/SingleMessage';
import { useContextSelector } from 'use-context-selector';
import { Context } from '../../Context';
import { MESSAGES_TYPE_ENUM } from '../../helpers/ENUMS';

const Messages = () => {
  const { messagesPage } = useContextSelector(Context, (state) => state);

  return (
    <PageWrapper>
      <div className='messages-wrapper'>
        <div className='message-header'>
          <h1 className='message-title'>Nowa wiadomość</h1>
          <a
            href='/import'
            className='underline-text'
          >
            Importuj wiadomość z pliku
          </a>
        </div>
        <SingleMessage display={messagesPage.currentType == MESSAGES_TYPE_ENUM.SINGLE} />
      </div>
    </PageWrapper>
  );
};

export default Messages;
