import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import validateAddContact, { validateAddContactResponse } from '../../../../helpers/validators/validateAddContact';
import useNotification from '../../../../helpers/notifications/notificationGenerator';
import { NOTIFICATION_ENUM } from '../../../../helpers/ENUMS.js';
import contactService from '../../../../services/contact.service';
import usePopup from '../../../../helpers/usePopup';
import GreenButton from '../../GreenButton';
const AddContactsPopUp = ({ fetchContacts }) => {
  const [contactData, setContactData] = useState({ first_name: '', last_name: '', description: '', phone_number: '', is_active: '' });

  const { generateNotification } = useNotification();
  const { closePopup } = usePopup();
  const handleInputChange = (e) => {
    // Sprawdź, czy id to 'phone_number' i czy wartość jest numeryczna
    if (e.target.id === 'phone_number' && !/^\d*$/.test(e.target.value)) {
      return; // Jeśli nie, to nie aktualizuj stanu
    }
    // Aktualizuj stan w przeciwnym przypadku
    setContactData({ ...contactData, [e.target.id]: e.target.value });
    if (e.keyCode === 13) {
      handleAddContact(e); // Wywołaj funkcję handleAddContact w przypadku kliknięcia Enter
    }
  };
  const handleAddContact = (e) => {
    e.preventDefault();
    const validateInfo = validateAddContact({ ...contactData });

    if (validateInfo.type == NOTIFICATION_ENUM.SUCCESS) {
      contactService.createContact({ ...contactData }).then((r) => {
        generateNotification({ ...validateAddContactResponse(r), time: 4000 });
        fetchContacts();
        closePopup();
      });
    }
  };

  const handleClosePopup = (e) => {
    e.preventDefault();
    closePopup();
  };

  return (
    <div className='add-contacts-popup'>
      <p className='title'>Dodaj kontakt</p>
      <Box
        component='form'
        sx={{ flexDirection: 'column' }}
        noValidate
        autoComplete='off'
        padding={5}
      >
        <div className='input-wrapper'>
          <TextField
            onChange={(e) => handleInputChange(e)}
            onKeyDown={handleInputChange}
            id='first_name'
            value={contactData.first_name}
            label='Imię'
            variant='standard'
          />
        </div>
        <div className='input-wrapper'>
          <TextField
            onChange={(e) => handleInputChange(e)}
            onKeyDown={handleInputChange}
            id='last_name'
            value={contactData.last_name}
            label='Nazwisko'
            variant='standard'
          />
        </div>
        <div className='input-wrapper'>
          <TextField
            onChange={(e) => handleInputChange(e)}
            onKeyDown={handleInputChange}
            id='phone_number'
            label='Numer telefonu'
            value={contactData.phone_number}
            variant='standard'
          />
        </div>
        <div className='button-wrapper'>
          <button
            onClick={(e) => handleClosePopup(e)}
            className='btn close-contant-button'
          >
            Anuluj
          </button>
          <GreenButton
            handleClick={(e) => handleAddContact(e)}
            noMargin
          >
            Dodaj
          </GreenButton>
        </div>
      </Box>
    </div>
  );
};

export default AddContactsPopUp;
