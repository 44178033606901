import { Context } from '../Context';
import { useContextSelector } from 'use-context-selector';

const usePopup = () => {
  const popup = useContextSelector(Context, (state) => state.popup);
  const setPopup = useContextSelector(Context, (state) => state.setPopup);
  const intervalId = useContextSelector(Context, (state) => state.intervalId);
  const setIntervalId = useContextSelector(Context, (state) => state.setIntervalId);

  const initPopup = (component) => {
    if (popup.component != component) {
      setPopup({ ...popup, component: component, display: true });
    }
  };

  const popUpState = popup.state;
  const clearIntervalManually = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
      console.log('Interwał został zatrzymany ręcznie.');
    }
  };
  const setPopUpState = (state) => {
    setPopup({ ...popup, state: state });
  };

  const openPopup = () => {
    console.log(popup);
    setPopup({ ...popup, display: true });
  };

  const closePopup = () => {
    setPopup({ ...popup, display: false });
  };

  return { initPopup, openPopup, closePopup, popup, popUpState, setPopUpState, clearIntervalManually, setIntervalId };
};

export default usePopup;
