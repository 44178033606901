import React, { useEffect, useState } from 'react';
import { CssTextField } from '../../../scss/settings/StyledMUIComponents';
import GreenButton from '../../Global/GreenButton';
import { handleFetchBasicData, handleFetchCountriesList, handleFetchInvoiceData, handlePatchInvoiceData } from '../../../services/profile.service';
import { MenuItem } from '@mui/material';
import usePopup from '../../../helpers/usePopup';
import SavePopup from './SavePopup';
import { useLocation, useNavigate } from 'react-router-dom';

const ProfileComponent = ({ setResetPassword, setDeleteAccount }) => {
  const [form, setForm] = useState({ email: '', password: '' });
  const [countries, setCountries] = useState([]);
  const [haventChanged, setHaventChanged] = useState(true);
  const [invoiceData, setInvoiceData] = useState({
    country: '',
    tax_no: '',
    name: '',
    address: '',
    postcode: '',
    city: '',
  });
  const [error, setError] = useState({});
  const { initPopup } = usePopup();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const fromPayment = searchParams.get('fromPayment');
  const handleChange = (e, type) => {
    if (type === 'form') {
      setForm((prevState) => {
        return { ...prevState, [e.target.name]: e.target.value };
      });
    } else if (type === 'invoice') {
      setInvoiceData((prevState) => {
        return { ...prevState, [e.target.name]: e.target.value };
      });
    }
    setHaventChanged(false);
    setError({});
  };
  const handleSaveInvoiceData = async () => {
    await handlePatchInvoiceData(invoiceData)
      .then((response) => response.json())
      .then(() => {
        setHaventChanged(true);
        if (fromPayment && fromPayment === 'true') {
          navigate('/shop?changedInvoiceData=true');
          initPopup(<SavePopup />);
        } else {
          initPopup(<SavePopup />);
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    handleFetchBasicData()
      .then((response) => response.json())
      .then((data) => {
        setForm((prevState) => {
          return { ...prevState, email: data.email };
        });
      })
      .catch((error) => console.log(error));
    handleFetchCountriesList()
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.results);
      })
      .catch((error) => console.log(error));
    handleFetchInvoiceData()
      .then((response) => response.json())
      .then((data) => {
        setInvoiceData(data);
      })
      .catch((error) => console.log(error));
  }, []);
  const invoice = [
    { label: 'Kraj', value: invoiceData.country, name: 'country', hasValue: invoiceData.country !== '' || invoiceData.country !== null },
    { label: 'NIP/Vat Number', value: invoiceData.tax_no, name: 'tax_no', hasValue: invoiceData.tax_no?.length > 0 },
    { label: 'Nazwa firmy', value: invoiceData.name, name: 'name', hasValue: invoiceData.name?.length > 0 },
    { label: 'Adres', value: invoiceData.address, name: 'address', hasValue: invoiceData.address?.length > 0 },
    { label: 'Kod pocztowy', value: invoiceData.postcode, name: 'postcode', hasValue: invoiceData.postcode?.length > 0 },
    { label: 'Miasto', value: invoiceData.city, name: 'city', hasValue: invoiceData.city?.length > 0 },
  ];
  return (
    <>
      <h1 className='profile-header'>Podstawowe dane</h1>
      <div className='inputs-container'>
        <CssTextField
          id='outlined-required'
          label='E-mail'
          value={form.email}
          name='email'
          disabled
          error={error.email || error.detail}
          hasValue={form.email?.length > 0}
          inputProps={{
            autoComplete: 'new-password',
          }}
        />
        <div className='profile-password'>
          <CssTextField
            id='outlined-required'
            label='Hasło'
            value={'examplepassword'}
            name='password'
            disabled
            type={'password'}
            onChange={(e) => handleChange(e, 'form')}
            error={error.password || error.detail}
            hasValue={form.password?.length > 0}
            inputProps={{
              autoComplete: 'new-password',
            }}
          />
          <span
            className={'underline-text'}
            onClick={() => setResetPassword(true)}
          >
            Zmień hasło
          </span>
        </div>
      </div>
      <h1 className='profile-header'>Dane do faktury</h1>
      <div className='inputs-container'>
        {invoice.map((item, index) => {
          if (item.name === 'country') {
            return (
              // <FormControl>
              //     <InputLabel id="demo-simple-select-label">{item.label}</InputLabel>
              <CssTextField
                key={index}
                labelId='demo-simple-select-label'
                id='outlined-required'
                label={item.label}
                value={item.value}
                name={item.name}
                select
                onChange={(e) => {
                  handleChange(e, 'invoice');
                }}
                hasValue={item.hasValue}
              >
                {countries.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={item.id}
                    >
                      {item.name}
                    </MenuItem>
                  );
                })}
              </CssTextField>
              // </FormControl>
            );
          } else {
            return (
              <CssTextField
                key={index}
                id='outlined-required'
                label={item.label}
                value={item.value}
                name={item.name}
                onChange={(e) => handleChange(e, 'invoice')}
                error={error.email || error.detail}
                hasValue={item.hasValue}
                type={item.name === 'tax_no' ? 'number' : 'text'}
                inputProps={{
                  inputMode: item.name === 'tax_no' ? 'numeric' : 'text',
                  pattern: item.name === 'tax_no' ? '[0-9]*' : null,
                }}
              />
            );
          }
        })}
      </div>
      <span className='info'>Dane zostaną użyte do wygenerowania najbliższej faktury</span>
      <GreenButton
        noMargin
        disabled={haventChanged}
        handleClick={handleSaveInvoiceData}
      >
        Zapisz zmiany
      </GreenButton>
      <span
        className='underline-text'
        style={{ marginTop: '130px' }}
        onClick={() => setDeleteAccount(true)}
      >
        Usuń moje konto
      </span>
    </>
  );
};

export default ProfileComponent;
